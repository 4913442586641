import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Global } from '../../services/global.service';
import * as moment from 'moment';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { error } from 'jquery';

@Component({
  selector: 'app-surveys-update',
  templateUrl: './surveys-update.component.html',
  styleUrls: ['./surveys-update.component.css']
})
export class SurveysUpdateComponent implements OnInit {

  survey_value: string = "Transportation";
  survey_type: string =  "transportation";
  loader: boolean = false;
  current_tab: string = 'Transfer';
  source_leed_id: any = "";
  destination_leed_id: any = "";
  leed_id_delete: any = "";
  leed_id_update: any = "";
  start_date: any = '';
  end_date: any = '';
  new_survey_date: any = '';
  emptyInputs: boolean = false;
  date_selected: boolean = false;
  count: any = 0;
  occupant_category: any = '';
  occupant_value: string = 'Select a category';
  overlapping_error_text: string = '';
  is_response_available: any = null;
  
  
  survey_id: any = '';
  responses: any = [];
  @Output() survey_selection = new EventEmitter<any>();
  selected_responses: any = [];
  select_all: boolean = false;
  page: number = 1;
	page_size: number = 30;
  page_count: number = 1;
  ordering: string = '-co2e';
  next_page: string | null = null;
  response_loader: boolean = false;
  overlapping_date: boolean = false;
  dateSelected: boolean = false

  constructor(
    private api: API,
    public global: Global,
    private alertService: alertService
  ) { }

  refreshForms() {
    this.source_leed_id = "";
    this.destination_leed_id = "";
    this.leed_id_delete = '';
    this.leed_id_update = '';
    this.occupant_category = 'Select a category';
    this.new_survey_date = '';
    this.start_date = "";
    this.end_date = "";
    this.page = 1;
	  this.page_size = 30;
    this.page_count = 1;
    this.is_response_available = false;
  };

  refreshDateRange(){
    this.start_date = "";
    this.end_date = "";
    this.count = 0;
  }
  
  
  getAllResponses(page: number, page_size: number, ordering?: string){
    this.global.removeDynamicDiv();
    this.select_all = false;
		if(this.page_size == 0 || this.page_size < 10)
		{
			this.page_size = 30;
		}
		
		if(this.page > this.page_count || this.page <= 0)
		{
			return;
		}

    if (this.leed_id_delete.length == 0) {
      this.alertService.alert('error', 'Enter Project ID', 5);
      return;
    }
		var start_date_input = moment(this.start_date).format("YYYY-MM-DD");
    var end_date_input = moment(this.end_date).format("YYYY-MM-DD");

		this.page = page;
		this.page_size = page_size;
		
		this.response_loader = true;
    if(this.survey_type == 'transportation'){
      this.survey_type = 'transit';
    }

    if(this.survey_type == 'human_experience'){
      this.survey_type = 'environment';
    }
    if((moment(this.start_date)).isAfter(moment(this.end_date)))
		{
			this.overlapping_date = true;
      this.overlapping_error_text = 'Overlapping date';
      return
		}
    if(this.start_date.length != 0 && this.end_date.length != 0)
    {
      this.overlapping_error_text = '';
      var url = '';
      if(this.survey_type == 'transit')
      {
        url = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?start=' + start_date_input + '&end=' + end_date_input + '&page=' + this.page + '&page_size=' + this.page_size + '&ordering=' + this.ordering;
      }
      else if(this.survey_type == 'environment')
      {
        url = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?start=' + start_date_input + '&end=' + end_date_input + '&page=' + this.page + '&page_size=' + this.page_size;
      }
      console.log(url);
      this.api.get(url).subscribe(
        data => {
          this.selected_responses = [];
			    this.select_all = false;
          if(data.count == 0)
          {
            this.is_response_available = false;
            this.alertService.alert('success', 'They are no survey for the project.', 5); 
            this.refreshDateRange();
          }
				  else if(data.count > 0)
          {
            this.is_response_available = true;
            this.count = data.count;
            this.page = this.page;
            this.page_size = this.page_size > this.count ? this.count : this.page_size;
            this.page_count = Math.ceil(this.count / this.page_size);

            for(var i = 0; i < data.results.length; i++)
            {
              if(this.selected_responses.indexOf(data.results[i].leed_id) > -1 || this.select_all)
              {
                data.results[i].select_all = true;
              }
            }

            this.responses = data.results;
            if (data.next == null)
            {
              this.next_page = null;
            }
            else
            {
              this.next_page = data.next.split('?page=')[1];
            }

          }
          
          this.response_loader = false;

        }, error => {
          this.loader = false;
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      );
    }
    else if (this.start_date.length == 0 && this.end_date.length == 0){
      var url1 = '';
      if(this.survey_type == 'transit')
      {
        url1 = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?page=' + this.page + '&page_size=' + this.page_size + '&ordering=' + this.ordering;
      }
      else if (this.survey_type == 'environment')
      {
        url1 = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?page=' + this.page + '&page_size=' + this.page_size;
      }

      this.api.get(url1).subscribe(
        data => {
          this.count = data.count;
          this.responses = data.results;
          if(data.count == 0)
          {
            this.is_response_available = false;
            this.alertService.alert('success', 'They are no survey for the project.', 5); 
            this.refreshDateRange();
          }
				  else if(data.count > 0)
          {
            this.is_response_available = true;
            this.count = data.count;
            this.page = this.page;
            this.page_size = this.page_size > this.count ? this.count : this.page_size;
            this.page_count = Math.ceil(this.count / this.page_size);

            for(var i = 0; i < data.results.length; i++)
            {
              if(this.selected_responses.indexOf(data.results[i].leed_id) > -1 || this.select_all)
              {
                data.results[i].select_all = true;
              }
            }
            this.responses = data.results;

            if (data.next == null)
            {
              this.next_page = null;
            }
            else
            {
              this.next_page = data.next.split('?page=')[1];
            }
          }
          this.response_loader = false;

        }, error => {
          
          //  this.loader = false;
           this.response_loader = false;
           this.alertService.alert('error', 'Something went wrong', 5);
        }
      );
    }
    
  }
  transferSurveyData() {

    this.closeModal();
    var payload = {};

    var start_date_input = moment(this.start_date).format("YYYY-MM-DD");
    var end_date_input = moment(this.end_date).format("YYYY-MM-DD");
    
    if(this.survey_type == 'transportation'){
      this.survey_type = 'transit';
    }

    if(this.survey_type == 'human_experience'){
      this.survey_type = 'environment';
    }

    if((moment(this.start_date)).isAfter(moment(this.end_date)))
		{
			this.overlapping_date = true;
      this.overlapping_error_text = 'Overlapping date';
      return
		}

    if(this.start_date.length != 0 && this.end_date.length != 0)
    {
      this.overlapping_error_text = '';
      payload =
      {
        "source_id": this.source_leed_id,
        "target_id": this.destination_leed_id,
        "start_date": start_date_input,
        "end_date": end_date_input
      }
      
    }
    
    else if (this.start_date.length == 0 && this.end_date.length == 0) {

      payload =
      {
        "source_id": this.source_leed_id,
        "target_id": this.destination_leed_id
      };
    }

    if(this.source_leed_id.length != 0)
    {
      if(this.start_date.length != 0 && this.end_date.length != 0)
      {
        this.api.get('/assets/LEED:' + this.source_leed_id + '/survey/' + this.survey_type + '/?start=' + start_date_input + '&end=' + end_date_input ).subscribe(
          data => {
            this.count = data.count;
          }, error => {
            
          }
        );
      }
      else if(this.start_date.length == 0 && this.end_date.length == 0){
        this.api.get('/assets/LEED:' + this.source_leed_id + '/survey/' + this.survey_type + '/').subscribe(
          data => {
            this.count = data.count;
          }, error => {
            
          }
        );
      }
    }
    

    if (this.source_leed_id.length == 0) {
      this.alertService.alert('error', 'Enter source LEED ID', 5);
      return;
    }
    else if (this.destination_leed_id.length == 0) {
      this.alertService.alert('error', 'Enter target LEED ID', 5);
      return;
    }
    this.loader = true;
    this.api.post('/optask/transfer' + this.survey_type + 'survey/', payload ).subscribe(
      data => {
        this.loader = false;
        if(this.count == 0)
        {
          this.alertService.alert('success', 'They are no survey for the source project.', 5); 
          this.refreshDateRange();
        }
        else if (this.count > 0)
        { 
          this.alertService.alert('success', 'Transfer complete. No. of surveys transferred to the target project are ' + this.count, 5);
          this.refreshForms();
        }
        this.refreshForms();
      }, error => {
        this.loader = false;
        try {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  deleteSurveyData (){

    var payload = {};
    var start_date_input = moment(this.start_date).format("YYYY-MM-DD");
    var end_date_input = moment(this.end_date).format("YYYY-MM-DD");
    if(this.survey_type == 'transportation'){
      this.survey_type = 'transit';
    }

    if(this.survey_type == 'human_experience'){
      this.survey_type = 'environment';
    }

    if(this.start_date.length != 0 && this.end_date.length != 0)
    (
      payload =
      {
        "source_id": this.leed_id_delete,
        "start_date": start_date_input,
        "end_date": end_date_input
      }
      
    );
    
    else if (this.start_date.length == 0 && this.end_date.length == 0) {

      payload =
      {
        "source_id": this.leed_id_delete
      };
    }

    console.log(payload);

    if(this.leed_id_delete.length != 0)
    {
      if(this.start_date.length != 0 && this.end_date.length != 0)
      {
        this.api.get('/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?start=' + start_date_input + '&end=' + end_date_input ).subscribe(
          data => {
            this.count = data.count;
          }, error => {
            this.loader = false;
            this.alertService.alert('error', 'Something went wrong', 5);
          }
        );
      }
      else if(this.start_date.length == 0 && this.end_date.length == 0){
        this.api.get('/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/').subscribe(
          data => {
            this.count = data.count;
          }, error => {
            this.loader = false;
            this.alertService.alert('error', 'Something went wrong', 5);
          }
        );
      }
    }
    

    if (this.leed_id_delete.length == 0) {
      this.alertService.alert('error', 'Enter source LEED ID', 5);
      return;
    }

    this.loader = true;
    this.api.delete('/optask/' + this.survey_type + 'survey/', payload ).subscribe(
      data => {
        this.loader = false;
        if(this.count == 0)
        {
          this.alertService.alert('success', 'They are no survey for the project.', 5); 
        }
        else if (this.count > 0)
        { 
          this.alertService.alert('success', 'Surveys deletion completed. No of surveys deleted are ' + this.count, 5);
          this.refreshForms();
        }
        this.refreshForms();
        
      }, error => {
        this.loader = false;
        try {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );

  }

  updateSurveyData (){

    this.closeModal();

    var payload = {};
    var start_date_input = moment(this.start_date).format("YYYY-MM-DD");
    var end_date_input = moment(this.end_date).format("YYYY-MM-DD");
    var new_survey_date_input = moment(this.new_survey_date).format("YYYY-MM-DD");
    
    if(this.survey_type == 'transportation'){
      this.survey_type = 'transit';
    }

    if(this.survey_type == 'human_experience'){
      this.survey_type = 'environment';
    }

    if((moment(this.start_date)).isAfter(moment(this.end_date)))
		{
			this.overlapping_date = true;
      this.overlapping_error_text = 'Overlapping date';
      return
		}

    if(this.start_date.length != 0 && this.end_date.length != 0 && this.new_survey_date != 0)
    {
      this.overlapping_error_text = '';
      payload =
      {
        "source_id": this.leed_id_update,
        "start_date": start_date_input,
        "end_date": end_date_input,
        "survey_date": new_survey_date_input
      }
    }
    
    else if (this.start_date.length == 0 && this.end_date.length == 0 && this.occupant_category.length == 0) {

      payload =
      {
        "source_id": this.leed_id_update,
        "survey_date": new_survey_date_input
      };
    }

    else if (this.occupant_category.length != 0 && this.start_date.length != 0 && this.end_date.length != 0)
    {   
      payload = 
      { 
        "source_id": this.leed_id_update,
        "occupant_category": this.occupant_category,
        "start_date": start_date_input,
        "end_date": end_date_input
      }
    }

    else if (this.leed_id_update.length != 0 && this.new_survey_date != 0)
    {
      payload =
      {
        "source_id": this.leed_id_update,
        "survey_date": new_survey_date_input
      };
    }

    console.log(payload);

    if(this.leed_id_update.length != 0)
    {
      if(this.start_date.length != 0 && this.end_date.length != 0)
      {
        this.api.get('/assets/LEED:' + this.leed_id_update + '/survey/' + this.survey_type + '/?start=' + start_date_input + '&end=' + end_date_input ).subscribe(
          data => {
            this.count = data.count;
          }, error => {
            this.loader = false;
            this.alertService.alert('error', 'Something went wrong', 5);
          }
        );
      }
      else if(this.start_date.length == 0 && this.end_date.length == 0){
        this.api.get('/assets/LEED:' + this.leed_id_update + '/survey/' + this.survey_type + '/').subscribe(
          data => {
            this.count = data.count;
          }, error => {
            this.loader = false;
            this.alertService.alert('error', 'Something went wrong', 5);
          }
        );
      }
    }
    
    if (this.leed_id_update.length == 0) {
      this.alertService.alert('error', 'Enter source LEED ID', 5);
      return;
    }

    this.loader = true;
    this.api.put('/optask/' + this.survey_type + 'survey/', payload ).subscribe(
      data => {
        this.loader = false;
        this.alertService.alert('success', 'Surveys updation completed.', 5);
        // if(this.count == 0)
        // {
        //   this.alertService.alert('success', 'They are no survey for the source project.', 5); 
        //   this.refreshDateRange();
        // }
        // else if (this.count > 0)
        // { 
        //   this.alertService.alert('success', 'Surveys updation completed. No of surveys updated are ' + this.count, 5);
        // }
        this.refreshForms();
        
      }, error => {
        this.loader = false;
        try {
          // this.alertService.alert('error', error.error[0].message, 5);
          this.alertService.alert('error', error.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );

  }

  checkInputs() {
    if (this.end_date != '' && this.start_date != '') {
      if (this.source_leed_id.length != 0 && this.destination_leed_id.length != 0) {
        this.emptyInputs = true;
      }
      else {
        this.emptyInputs = false;
      }
    }
    if (this.end_date != '' && this.start_date != '') {
      if(this.leed_id_delete != 0){
        this.emptyInputs = true;
      }
      else {
        this.emptyInputs = false;
      }
    }
    else if (this.start_date == '' && this.end_date == ''){
      if(this.source_leed_id.length != 0 && this.destination_leed_id != 0){
        this.emptyInputs = true;
     }
    }
    
  };

  checkDates(){

    if (this.end_date != '' && this.start_date != '') {
      if((moment(this.start_date)).isAfter(moment(this.end_date))){
        this.overlapping_date = true;
        this.overlapping_error_text = 'Overlapping date';
      }
     else {
      this.overlapping_date = false;
      this.overlapping_error_text = '';
     }
    }

  }

  changeSurveyType(type: string, val: string) {
    this.survey_type = type;
    this.survey_value = val;
    this.refreshForms();
  };

  getSatisfaction(type: string, val: string){
    
  }

  changeOccupantType(type: string, val: string) {
    this.occupant_category = type;
    this.occupant_value = val;
  };

  toggleTab(tab: string)
	{
		if(tab == this.current_tab)
		{
			this.refreshForms();
      return;
		}
		this.current_tab = tab;
    this.refreshForms();
    this.overlapping_error_text = '';
    this.emptyInputs = false;
    this.is_response_available = false;
	};

  getAllSurveyIDs(ids: any | []){

		this.loader = true;
    var url = ''
    if(this.survey_type = 'transit'){
      console.log('Survey type is 2 = ' + this.survey_type);
      url = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?page=' + this.page + '&page_size=' + this.page_size + '&ordering=' + this.ordering
    }
    else if (this.survey_type = 'environment')
    {
      console.log('Survey type is 3 = ' + this.survey_type);
      url = '/assets/LEED:' + this.leed_id_delete + '/survey/' + this.survey_type + '/?page=' + this.page + '&page_size=' + this.page_size
    }

    this.api.get(url).subscribe(
      data => {
			
				this.selected_responses = [];
        // ids = this.selected_responses;
				for(let i = 0; i < data.length; i++)
				{
					if(this.selected_responses.indexOf(data[i].id) == -1)
					{
						this.selected_responses.push(data[i].id);
					}
				}
				this.loader = false;
				this.survey_selection.emit(this.selected_responses);
        ids = this.selected_responses;
			},
      error => {
          this.loader = false;
          this.alertService.alert('error', 'Something went wrong', 5);
      }
    );
  }
  
  toggleCheck(index: string | number, select_all?: any)
	{
    if(select_all != undefined)
		{
			this.selected_responses = select_all;
		}

    if(index == 'all')
		{
			if(!this.select_all)
			{
				this.selected_responses = [];
			}
			for (var i = 0; i < this.responses.length; i++)
			{
				this.responses[i].select_all = this.select_all;
			}
		}
		else
		{
			this.responses[index].select_all = !this.responses[index].select_all;
			if(!this.responses[index].select_all)
			{
				this.select_all = false;
			}
		}

		for (var i = 0; i < this.responses.length; i++)
		{
			if(this.responses[i].select_all && this.selected_responses.indexOf(this.responses[i].id) == -1)
			{
				this.selected_responses.push(this.responses[i].id);
			}
			else if(!this.responses[i].select_all &&  this.selected_responses.indexOf(this.responses[i].id) > -1)
			{
				this.selected_responses.splice(this.selected_responses.indexOf(this.responses[i].id), 1)
			}
		}

		if(this.selected_responses.length == this.count)
		{
			this.select_all = true;
		}
		this.survey_selection.emit(this.selected_responses);
	};


  deleteSelectedResponses()
	{
    this.closeModal();
    var payload = 
    {
     "source_id": this.leed_id_delete,
     "survey_ids": this.selected_responses
    }
		
		this.api.delete('/optask/' + this.survey_type + 'survey/', payload)
		.subscribe(
			data =>
			{
				// this.selected_responses = [];
        this.refreshForms();
        // this.getAllResponses(this.page, this.page_size, this.ordering)
				this.alertService.alert('success', 'Selected '  + this.selected_responses.length + ' surveys have been deleted', 5);
        this.selected_responses = [];
			},
			error => {
        this.alertService.alert('error', 'Something went wrong', 5);
      }
		);
	}
  @ViewChild('modal') private modal!: ModalComponent;
  openModal()
	{
    if(this.current_tab == 'Transfer')
    {
      if (this.source_leed_id.length == 0 || this.destination_leed_id.length == 0)  
      {
        this.alertService.alert('error', 'Enter source and target IDs', 5);
        return;
      }
      else 
      {
        this.modal.open();
      }
    }
    if(this.current_tab == 'Update')
    {
      if (this.leed_id_update.length == 0)  
      {
        this.alertService.alert('error', 'Enter valid data', 5);
        return;
      }
      else if(this.new_survey_date != '' || this.occupant_category != '')
      {
        console.log('Added date----' + this.occupant_category + '&' + this.new_survey_date)
        this.modal.open();
      }
    }
    if(this.current_tab == 'Delete')
    {
      this.modal.open();
    }
    
	}

  closeModal()
	{
		this.modal.close();
	}

  enableFdatePicker(): void {
    (<any>$('#end_date')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.end_date = $('#end_date').val();
      }
    );

    (<any>$('#start_date')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.start_date = $('#start_date').val();
      }
    );
    (<any>$('#new_survey_date')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.new_survey_date = $('#new_survey_date').val();
        this.dateSelected = true;
        console.log('Date selected flag is---' +this.dateSelected)
      }
    );
  }

  ngOnInit(): void {
    
  }

}
