
<div class="content-wrap">
	<div id="content" class="content admin_tool_content">		
		<div class="admin_team_body">
			<h3 class="teamWrapper">Add Project Team<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb20 pt20">Please provide details below to add user to a project. Please use this <a href="../../assets/pdf/Team Member.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="leed_id" name="leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">User ID</label>
                                <div class="col-sm-7">
                                    <div class="input-group"><span class="input-group-addon"><i class="fa fa-user"></i></span>
                                        <input maxlength="40" [(ngModel)]="user_id" name="user_id" id="prepended-input" class="form-control" size="16" type="text" placeholder="User ID">
                                    </div>
                                </div>
                                <div class="col-sm-2" [hidden]="user_id == ''">
                                    <button (click)="addIDS(user_id)" class="btn btn-default"> <i
                                        class="fa fa-plus mr10" aria-hidden="true"></i>Add</button>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="user_emails.length > 0">
                                <label for="normal-field" class="col-sm-2"></label>
                                <div class="col-sm-10 flex">
                                    <div class="admin_option" *ngFor="let id of user_emails">
                                        <!-- <span>{{teamMembers[id.key].user_email}}</span> -->
                                         <span>{{id}}</span>
                                        <span (click)="removeID(id)" class="ml10"><i class="fa fa-times"
                                                aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">Role</label>
                                <div class="col-sm-7">
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                        <span>{{role}}</span>
                                        <span class="caret ml8"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="changeRole('ARC Project Admin', 'ZRPO80')"><a>ARC Project Admin</a></li>
                                            <li (click)="changeRole('ARC Project Team Member', 'ZRPO81')"><a>ARC Project Team Member</a></li>
                                            <li (click)="changeRole('ARC Project Read Only', 'ARC01')"><a>ARC Project Read Only</a></li>
                                            <li (click)="changeRole('GBCI Reviewer', 'ARC02')"><a>GBCI Reviewer</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <div [ngClass]="loader ? 'not-active' : ''">
                                        <ul style="padding-left: 0px;" class="wizard display-block pull-left mr10">
                                            <li class="next list_style_none align-right">
                                                <button (click)="saveRole()" class="btn btn-default"><i class="fa fa-plus" aria-hidden="true"></i> Create</button>
                                            </li>
                                        </ul>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>   
                                    </div>
                                </div>
                            </div>                            
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
        <div id="admin_team_body" class="pt30">
			<h3 class="teamWrapper admin_tool_content_portfolio">View/Delete Project Team<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content" [ngClass]="delete_loader ? 'not-active' : ''">
                <app-loader *ngIf="delete_loader"></app-loader>
                <header>
                    <h5 class="mb20 pt20">Enter Project ID to view team members. Please use this <a href="../../assets/pdf/Team Member.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">Project ID</label>
                                <div class="col-sm-7">                                    
                                    <!-- <input [(ngModel)]="delete_leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID"> -->
                                    <input [(ngModel)]="delete_leed_id" name= "delete_leed_id" (input)="checkInputs()" type="text" class="form-control" placeholder="Project ID">
                                </div>
                                <div class="wizard display-block">
                                    <button (click)="getTeam()" class="btn btn-default pull-left mr10" [ngClass]="checkInput ? '' : 'not-active'" ><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                </div>
                            </div>                    
                        </fieldset>
                    </form>

                    <table class="table table-striped table-hover no-footer">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>EMAIL</th>
                                <th>ACCESS</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let team_member of team_members; let idx = index" [hidden]="team_member.Responsibility == 'D'">
                                <td>{{team_member.Firstname}} {{team_member.Lastname}}</td>
                                <td>{{team_member.Useralias}}</td>
                                <td><span class="capitalize">{{team_member.Roledescription.toLowerCase()}}</span></td>
                                <td>
                                    <div title="Remove User" class="ml10 delete_report floatNone" (click)="removeUser(team_member)"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <div class="mb50">
            <h1 class="page-title pl0 mb35">View Projects by User<span class="fw-semi-bold"></span></h1>
            <section class="widget" [ngClass]="loader ? 'not-active' : ''">
                <app-loader *ngIf="loader"></app-loader>
                <div class="widget-body">
                    <div class="arc-pro-add-user">
                        <header>
                            <h5 class="mb10">Provide a user email to view the list of projects that the user have access to. Please use this <a href="../../assets/pdf/Team-member-lookup.pdf" download>guide</a> to learn how to use this tool.</h5>
                        </header>
                        <div class="fs16 fw-500 line_height_24 mb8">
                            Search user 
                        </div>
                        <div class="relative-position">
                            <input autocomplete="off" (focus)="isSearch(true)" (input)="searchUser(search_user)" [(ngModel)]="search_user" placeholder="Search" class="arc-pro-search" [ngModelOptions]="{standalone: true}">
                            <svg class="arc-pro-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            <div *ngIf="search_results.length > 0 && is_search" class="user-list">
                                <div [ngClass]="user.user_role == 'arc_pro' ? 'darkgray' : ''" class="user-item" *ngFor="let user of search_results">
                                    <div class="flex">
                                        <div class="mr10" [innerHtml]="getUser(user, search_user)"></div>
                                        <div *ngIf="user.user_role == 'arc_pro'" class="arc_logo_racetrack"></div>
                                    </div>
                                    <button (click)="selectUser(user)" class="add-user-button">Select</button>
                                </div>
                            </div>
                            <div *ngIf="search_results.length == 0 && is_search && search_user.length > 0 && !searching_user" class="user-list">
                                <div class="user-item align-center fs16 gray_color_707479 block">
                                    No user found
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="projects.length > 0 && search_user != ''"  class="pull-right">
                        <a (click)="clearList()" class="pb40" *ngIf="search_user != ''">Clear</a>
                    </div>
                    <div *ngIf="projects.length > 0 && search_user != ''" class="mt30">
                        <div class="align_lr">
                            <div><span class="fw-500">{{projects.length}}</span> Projects for <span class="fw-500">{{selected_user.email}}</span></div>
                            <div>
                                <button (click)="exportData()" class="btn btn-default"><i class="fa fa-download mr5" aria-hidden="true"></i> Export</button>
                            </div>
                        </div>
                        <table class="table table-striped mt20">
                            <thead>
                                <tr>
                                    <th class="bold">Project ID</th>
                                    <th class="bold">Name</th>
                                    <th class="bold">City</th>
                                    <th class="bold">Country</th>
                                    <th class="bold">Project Status</th>
                                    <th class="bold">Organization</th>
                                    <th class="bold">Confidential</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let asset of projects; let idx = index">
                                    <td>{{asset.leed_id}}</td>
                                    <td>{{asset.name}}</td>
                                    <td>{{asset.city}}</td>
                                    <td>{{asset.country}}</td>
                                    <td>{{getStatus(asset.status)}}</td>
                                    <td>{{asset.organization}}</td>
                                    <td>{{asset.confidential == true ? 'Yes' : 'No'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <div id="admin_team_body" class="pt30">
            <h3 class="teamWrapper admin_tool_content_portfolio">Add Portfolio Team<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb20 pt20">Please provide details below to add a user to a portfolio. Please use this <a href="../../assets/pdf/Team Member.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">Portfolio ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="pf_id" name="pf_id" type="text" id="normal-field" class="form-control" placeholder="Portfolio ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">User ID</label>
                                <div class="col-sm-7">
                                    <div class="input-group"><span class="input-group-addon"><i class="fa fa-user"></i></span>
                                        <input maxlength="40" [(ngModel)]="pf_user_id" name = "pf_user_id" id="prepended-input" class="form-control" size="16" type="text" placeholder="User ID">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">Role</label>
                                <div class="col-sm-7">
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                        <span>{{pf_role}}</span>
                                        <span class="caret ml8"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="changeRolePF('Can Read', 'can_read')"><a>Can Read</a></li>
                                            <li (click)="changeRolePF('Can Edit', 'can_edit')"><a>Can Edit</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <div [ngClass]="loader ? 'not-active' : ''">
                                        <ul style="padding-left: 0px;" class="wizard display-block pull-left mr10">
                                            <li class="next list_style_none align-right">
                                                <button (click)="saveRolePF()" class="btn btn-default"><i class="fa fa-plus" aria-hidden="true"></i> Create</button>
                                            </li>
                                        </ul>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>
                            
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>

