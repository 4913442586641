import {
  Directive,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Directive({
  selector: 'ngInclude'
})
export class NgIncludeDirective implements OnInit
{
	type = 'template';
	templateUrl = ''

	constructor(private element: ElementRef, private http: HttpClient)
	{

	}
	parseTemplate(res: Response)
	{
		if (this.type == 'template')
		{
			this.element.nativeElement.innerHTML = res.text();
		}
	}

	ngOnInit()
	{
		// this.http.get(this.templateUrl).map(res => this.parseTemplate(res));
	}

}