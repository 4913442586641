
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="">
            <h1 class="page-title pl0">Deactivate Project<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto">
                <header>
                    <h5 class="mb10">Enter LEED ID to deactivate the project. Please use this <a href="../../assets/pdf/Project-deactivation.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7 flex">
                                    <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control mr10"
                                        placeholder="Press Enter or Click Add" [ngModelOptions]="{standalone: true}">
                                    <button (click)="addIDS(leed_id)" class="btn btn-default"> <i
                                            class="fa fa-plus mr10" aria-hidden="true"></i>Add</button>
                                </div>
                            </div>
                            <div *ngIf="leed_ids.length > 0" class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">Selected ID's</label>
                                <div class="col-sm-7 flex">
                                    <div class="admin_option" *ngFor="let id of leed_ids">
                                        <span>{{id}}</span>
                                        <span (click)="removeID(id)" class="ml10"><i class="fa fa-times"
                                                aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <div [ngClass]="loader ? 'not-active' : ''">
                                        <ul style="padding-left: 0px;" class="wizard display-block pull-left mr10">
                                            <li class="next list_style_none align-right">
                                                <button (click)="deactivate()" class="btn btn-default">
                                                    Deactivate</button>
                                            </li>
                                        </ul>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="left-right-align pb10">
                                <div *ngIf="status.success.length > 0 || status.already_deactivated.length > 0 || status.project_not_found.length > 0"
                                    class="fw-500">
                                    Operational task results:
                                </div>
                                <div (click)="clearStatus()"
                                    *ngIf="status.success.length > 0 || status.already_deactivated.length > 0 || status.project_not_found.length > 0"
                                    class="cursor-pointer grey-text fs13">
                                    <span><i class="fa fa-ban mr10"></i>Clear</span>
                                </div>
                            </div>
                            <div class="form-group border-top-grey pt15" *ngIf="status.success.length > 0">
                                <label for="normal-field" class="col-sm-2 control-label">Projects deactivated
                                    successfully:</label>
                                <div class="col-sm-7 flex">
                                    <div class="admin_option" *ngFor="let id of status.success">
                                        <span>{{id}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group border-top-grey pt15" *ngIf="status.already_deactivated.length > 0">
                                <label for="normal-field" class="col-sm-2 control-label">Projects already
                                    deactivated:</label>
                                <div class="col-sm-7 flex">
                                    <div class="admin_option" *ngFor="let id of status.already_deactivated">
                                        <span>{{id}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group border-top-grey pt15" *ngIf="status.project_not_found.length > 0">
                                <label for="normal-field" class="col-sm-2 control-label">Projects not found:</label>
                                <div class="col-sm-7 flex">
                                    <div class="admin_option" *ngFor="let id of status.project_not_found">
                                        <span>{{id}}</span>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>