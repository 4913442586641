
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">		
        <div class="admin_certification_body">
            <h3 class="certificationWrapper">Add Certification<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="pb20 pt20">Please provide the details below to add new Certification. Please use this <a href="../../assets/pdf/Certifications.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="leed_id_cert" name="leed_id_cert" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Rating System</label>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                    <span class="capitalize">{{rating_system_text}}</span>
                                    <span class="caret ml8"></span>
                                    </button>
                                    <ul class="dropdown-menu admin_rs_dropdown">
                                        <li *ngFor="let rating_system of rating_systems" (click)="selectRatingSystem(rating_system)" class="energy_unit">
                                            <a>{{rating_system.name}} ({{rating_system.value}})</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Certification Level</label>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default" type="button" data-toggle ="dropdown" >
                                    <span class="capitalize">{{certification_level}}</span>
                                    <span class="caret ml8"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeCert('platinum')"  class="energy_unit"><a>Platinum</a></li>
                                        <li (click)="changeCert('gold')"  class="energy_unit"><a>Gold</a></li>
                                        <li (click)="changeCert('silver')"  class="energy_unit"><a>Silver</a></li>
                                        <li (click)="changeCert('certified')" class="energy_unit"><a>Certified</a></li>
                                        <li (click)="changeCert('denied')" class="energy_unit"><a>Denied</a></li>
                                        <li (click)="changeCert('pre-certified')" class="energy_unit"><a>Pre-Certified</a></li>
                                        <li (click)="changeCert('5-STAR')" class="energy_unit"><a>5-STAR</a></li>
                                        <li (click)="changeCert('4-STAR')" class="energy_unit"><a>4-STAR</a></li>
                                        <li (click)="changeCert('3-STAR')" class="energy_unit"><a>3-STAR</a></li>
                                        
                                        <li (click)="changeCert('parksmart-pioneer')"  class="energy_unit"><a>Parksmart Pioneer</a></li>
                                        <li (click)="changeCert('parksmart-gold')"  class="energy_unit"><a>Parksmart Gold</a></li>
                                        <li (click)="changeCert('parksmart-silver')"  class="energy_unit"><a>Parksmart Silver</a></li>
                                        <li (click)="changeCert('parksmart-bronze')"  class="energy_unit"><a>Parksmart Bronze</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Source</label>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                    <span class="capitalize">{{source}}</span>
                                    <span class="caret ml8"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeSource('Arc')"  class="energy_unit"><a>Arc</a></li>
                                        <li (click)="changeSource('LO')" class="energy_unit"><a>LO</a></li>
                                        <li (click)="changeSource('LEED Canada')" class="energy_unit"><a>LEED Canada</a></li>
                                        <li (click)="changeSource('LEED India')" class="energy_unit"><a>LEED India</a></li>
                                        <li (click)="changeSource('Star')" class="energy_unit"><a>Star</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Source ID</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="source_id_cert" name="source_id_cert" type="text" id="normal-field" class="form-control">
                                </div>
                            </div>
        
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Energy score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="energy_score" name="energy_score" type="text" id="energy_score" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Water score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="water_score" name="water_score" type="text" id="water_score" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Waste score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="waste_score" name="waste_score" type="text" id="waste_score" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Transportation score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="transportation_score" name="transportation_score" type="text" id="transportation_score" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Human experience score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="human_experience_score" name="human_experience_score" type="text" id="human_experience_score" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Base score</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="base_score_cert" name="base_score_cert" type="text" id="base_score_cert" class="form-control">
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Certification Points</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" [(ngModel)]="certification_points" name="certification_points" type="text" id="normal-field" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Date Certified/Awarded/Denied</label>
                                <div class="col-sm-7">
                                    <input (input)="checkInputsCert()" id="date_certified" [(ngModel)]="date_certified" name="date_certified" class="col-sm-7 form-control" [ngInit]="enableFdatePicker()">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">Certification Term</label>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                    <span class="capitalize">{{term}}</span>
                                    <span class="caret ml8"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeTerm('N/A')"  class="energy_unit"><a>N/A</a></li>
                                        <li (click)="changeTerm('1')" class="energy_unit"><a>1</a></li>
                                        <li (click)="changeTerm('2')" class="energy_unit"><a>2</a></li>
                                        <li (click)="changeTerm('3')" class="energy_unit"><a>3</a></li>
                                        <li (click)="changeTerm('4')" class="energy_unit"><a>4</a></li>
                                        <li (click)="changeTerm('5')" class="energy_unit"><a>5</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <button (click)="saveCert()" [ngClass]="checkInputCert ? '' : 'not-active'" class="btn btn-default pull-left mr10">
                                        <i class="icon-save" aria-hidden="true"></i> Save
                                    </button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                            <div class="mb10 pt20">Note: If the certification is achieved through Arc please return the project from review by going to Review.</div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div> 
        <div class="admin_certification_body">
            <h3 class="certificationWrapper">Base Score<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="pb20 pt20">Please provide the details below to change the Base Score</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <input (input)="checkInputs()" [(ngModel)]="leed_id" name="leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Base Score (Percentage)</label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <!-- <input (input)="checkInputs()" [(ngModel)]="base_score" name="base_score" type="text" id="normal-field" class="form-control"> -->
                                    <input value="100" name="base_score" type="text" id="normal-field" class="form-control" readonly>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input"></label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <button (click)="saveStatus()" [ngClass]="checkInput ? '' : 'not-active'" class="btn btn-default pull-left mr10">
                                        <i class="icon-save" aria-hidden="true"></i> Save</button>
                                    <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                            <div class="mb10 pt20">* Provide 100% base score to a project that have been previously LEED-certified</div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>                
        <div id="admin_certification_body" class="pt30">
			<h3 class="teamWrapper admin_tool_content_portfolio">Certifications<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content" [ngClass]="loader ? 'not-active' : ''">
                <header>
                    <h5 class="pb20 pt20">Enter the Project ID to view the certifications</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">Project ID</label>
                                <div class="col-sm-7">                                    
                                    <!-- <input [(ngModel)]="delete_leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID"> -->
                                    <input [(ngModel)]="leed_id_cert_list" name= "leed_id_cert_list" type="text" class="form-control" placeholder="Project ID">
                                </div>
                                <div class="wizard display-block">
                                    <button (click)="getCertification()" class="btn btn-default pull-left mr10"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                </div>
                            </div>                    
                        </fieldset>
                    </form>
                    <table class="table table-striped table-hover no-footer">
                        <thead>
                            <tr>
                                <th>Rating System</th>
                                <th>Level</th>
                                <th>Points</th>
                                <th>Date Certified</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let certification of mycertifications; let idx = index">
                                <td>{{global.showRatingSystemLabel(certification.rating_system.value)}}</td>
                                <td class="capitalize">{{certification.certification_level}}</td>
                                <td><span class="capitalize">{{certification.certification_points}}</span></td>
                                <td>{{certification.date_certified}}</td>
                                <td>
                                    <div title="Remove Certification" class="ml10 delete_report floatNone" (click)="removeCertification(idx, certification)"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>    
</div>
