import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from 'src/app/services/global.service';

@Component({
  selector: 'app-admin-pre-payment',
  templateUrl: './admin-pre-payment.component.html',
  styleUrls: ['./admin-pre-payment.component.css']
})
export class AdminPrePaymentComponent implements OnInit {
  review_data: any = {};
  bld_data: any = {};
  checkLEED: boolean = false;
  loader: boolean = false;
  not_active: boolean = false;
  leed_id: any = '';

  constructor(
    public global: Global,
    private api: API,
    private alertService: alertService
  ) { }

  checkLEEDID() {
    if (isNaN(this.leed_id) || String(this.leed_id).length == 0) {
      this.checkLEED = false;
    }
    else {
      this.checkLEED = true;
    }
  };

  searchProject() {
    if (!this.checkLEED) {
      return false;
    }
    this.bld_data = {};
    this.loader = true;
    this.api.get('/assets/LEED:' + this.leed_id + '/review/').subscribe(
      data => {
        if (data.building) {
          this.bld_data = data.building;
        }

        if (data.review_payment) {
          this.review_data = data.review_payment[0]
        }

        if (this.review_data.bypass_payment) {
          $('#bypass_payment').prop('checked', true);
        }
        else {
          $('#bypass_payment').prop('checked', false);
        }

        if (this.review_data.bypass_area) {
          $('#bypass_area').prop('checked', true);
        }
        else {
          $('#bypass_area').prop('checked', false);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.review_data = {};
        this.bld_data = {};
        try {
          this.alertService.alert('error', error.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  makeAction(review_obj: any, id: any) {
    this.not_active = true;
    var putData: any = {};
    if (id == 'bypass_payment') {
      putData = { "bypass_payment": $('#bypass_payment').prop('checked') };
    }
    else if (id == 'bypass_area') {
      putData = { "bypass_area": $('#bypass_area').prop('checked') };
    }
    this.api.put('/assets/LEED:' + this.leed_id + '/review/ID:' + review_obj.id + '/', putData ).subscribe(
      data => {
        this.not_active = false;
        this.review_data = data;

        if (this.review_data.bypass_payment) {
          $('#bypass_payment').prop('checked', true);
        }
        else {
          $('#bypass_payment').prop('checked', false);
        }

        if (this.review_data.bypass_area) {
          $('#bypass_area').prop('checked', true);
        }
        else {
          $('#bypass_area').prop('checked', false);
        }
        this.alertService.alert('success', 'Updated successfully', 5);
      }, error => {
        this.not_active = false;
        this.alertService.alert('error', 'Something went wrong', 5);
      }
    );
  };

  ngOnInit(): void {
  }

}
