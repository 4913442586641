
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="">
            <h1 class="page-title pl0">Add Telecommute survey data<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto">
                <header>
                    <h5 class="mb10">Enter telecommute data for project. Please use this <a href="../../assets/pdf/Add-telecommute-data.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset [ngClass]="loader ? 'not-active' : ''">
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control"
                                        placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">Recompute Date</label>
                                <div class="col-sm-7">
                                    <input id="commute_date" [(ngModel)]="commute_date" class="col-sm-7 form-control"
                                        [ngInit]="enableFdatePicker()" name="commute_date">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">Commuters</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="teleworkers" type="text" id="normal-field" class="form-control"
                                        placeholder="Enter Commuters" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <div [ngClass]="loader ? 'not-active' : ''">
                                        <ul style="padding-left: 0px;" class="wizard display-block pull-left mr10">
                                            <li class="next list_style_none align-right">
                                                <button (click)="addData()" class="btn btn-default"><i
                                                        class="fa fa-plus" aria-hidden="true"></i> Add</button>
                                            </li>
                                        </ul>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>