import { Component, OnInit, ViewChild } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { Global } from '../../services/global.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from '../../models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { contains } from 'jquery';

@Component({
  selector: 'app-owner-info',
  templateUrl: './owner-info.component.html',
  styleUrls: ['./owner-info.component.css']
})
export class OwnerInfoComponent implements OnInit {

  config = Config;
  leed_id: any = '';
  all_organizations: any = [];
  owner_email: string = "";
  owner_name: string = "";
  ownerType: any = [];
  owner_type: string = '';
  loader: boolean = false;
  checkInput: boolean = false;
  searchingOrganization: boolean = false;
  canceler: any = '';
  owner_country: string = '';
  countries: any = [];
  
  @ViewChild('modal') private modal!: ModalComponent;
  loading_org_list: boolean = false;

  constructor(
    private api: API,
    private alertService: alertService,
    public global: Global,
  ) { }

  no_error = {
		'error': false,
		'help': undefined
	};

	formdata_error: any = {
    'leed_id': this.no_error,
		'organization': this.no_error,
		'owner_email': this.no_error,
		'owner_name': this.no_error,
    'owner_type': this.no_error,
    "manageEntityCountry": this.no_error
	};

  formdata: any = {
    'leed_id': '',
		'organization': '',
		'owner_email': '',
		'owner_name': '',
    'owner_type': '',
    'manageEntityCountry': 'US'
	}
  
  refreshForms() {
    this.leed_id = "";
    this.formdata.organization = "";
    this.owner_email = "";
    this.owner_name = "";
    this.owner_type = "";
    this.formdata.manageEntityCountry = "US";
    this.loader = false;
  };

  checkInputs() {
   
    if (isNaN(this.leed_id) || this.formdata.organization == '' || this.owner_email == '' || this.owner_name == ''  ) {
      this.checkInput = false;
    }
    else {
      this.checkInput = true;
    }
  };

  searchOrganization(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
    this.formdata_error['organization'] = {
			'error': true,
			'help': ''
		};
		this.searchEnableOrganization();
		query = query.substr(0, 100);
		this.loading_org_list = true;
		this.canceler = this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(data =>
		{
			this.loading_org_list = false;
			this.all_organizations = data.owner_type;
		}, error =>
		{
			this.loading_org_list = false;
		});
	}

  saveOwnerInfo() {
    this.loader = true;
    var putData =
    {
      organization : this.formdata.organization,
      owner_email : this.owner_email,
      owner_name : this.owner_name,
      ownerType : this.owner_type,
      manageEntityCountry: this.formdata.manageEntityCountry
    };

    this.api.put('/assets/LEED:' + this.leed_id + '/', putData).subscribe(
      data => {
        this.refreshForms();
        this.alertService.alert('success', 'Owner info updated', 5);
      }, error => { 
        this.loader = false;
        try
        {
          if (error.status == 404 && 'detail' in error.error){
            this.alertService.alert('error', error.error.detail, 10);
          }
        }
        catch(e) 
        {}
      }
    );
  };

  getDataFromModal(event: any)
	{
		this.formdata.organization = event.formdata;
		this.modal.close();
	}

  openModal()
	{
		this.modal.open();
	}

  closeModal()
	{
		this.modal.close();
	}

	searchDisableOrganization()
	{
		this.searchingOrganization = false;
	}
	
	searchEnableOrganization()
	{
		this.searchingOrganization = true;
	}

	selectOrganization(org: string)
	{
		this.formdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.formdata.organization = org;
		this.searchDisableOrganization();
	}

  getOwnerType(){

    this.api.getAsset(
			'../../assets/json/ownerType.json'
		).subscribe(data => 
		{
			this.ownerType = data;
		});
  }

  ngOnInit(): void {
    this.getOwnerType();
    this.refreshForms();
    if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
			});
		}
		else
		{
			this.countries = this.global.getCountryList()
		}
  }

}
