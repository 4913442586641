import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Config } from 'src/app/models/env-vars.model';
import * as moment from 'moment';


@Component({
  selector: 'app-subscription-discount',
  templateUrl: './subscription-discount.component.html',
  styleUrls: ['./subscription-discount.component.css']
})
export class SubscriptionDiscountComponent implements OnInit {
  regex: any = this.validator.regex;

  loader: boolean = false;
  valid_inputs: boolean = false;
  username: any = '';
  count: any = null;
  months: any = null;
  subscription_value: any = "Arc essentials";
  subscription_type: any = "arc_essentials"
  leed_id: any = null;
  
  // offline subscription
  config = Config;
  loading: boolean = false;
  canceller_search_user: any = undefined;
  search_user_status: boolean = false;
  search_user: any = '';
  search_project: any = '';
  search_results: any = [];
  selected_users: any = [];
  selected_projects: any = [];
  mode_project: any = 'free';
  mode_user: any = 'free';
  subscription_value_offline: any = 'Arc Essentials';
  is_search: boolean = false;
  searching_user: boolean = false;
  expire_date: any = '';
  expire_date_project: any = '';
  expire_date_project_ea: any = '';
  canceler: any;

  refreshForm(){
    this.leed_id = '';
    this.username = '';
    this.months = '';
    this.count = '';
  }

  changeSubscriptionType(type: any, val: any) {
    this.subscription_type = type;
    this.subscription_value = val;
    this.checkInputs();
  };

  changeSubscriptionTypeOffline(type: any)
  {
      this.subscription_value_offline = type;
  };

  validateEmail(email: any) {
    if (this.regex["email"].test(email)) {
      return true;
    }
    else {
      return false;
    }
  };

  validateLEEDId(leed_id: any) {
    if (this.regex["PrevCertProdId"].test(leed_id)) {
      return true;
    }
    else {
      return false;
    }
  };

  validateNumber(val: any) {
    if (this.regex["time_spent_by_riders"].test(val)) {
      return true;
    }
    else {
      return false;
    }
  };

  checkInputs() {
    if (this.subscription_type == "arc_essentials") {
      if (this.validateEmail(this.username) && this.validateNumber(this.count) && this.validateNumber(this.months)) {
        this.valid_inputs = true;
      }
      else {
        this.valid_inputs = false;
      }
    }
    else {
      if (this.validateLEEDId(this.leed_id) && this.validateNumber(this.months)) {
        this.valid_inputs = true;
      }
      else {
        this.valid_inputs = false;
      }
    }
  };

  saveStatus() {
    this.loader = true;
    var payload: any =
    {
      "object": this.leed_id,
      "months": parseInt(this.months),
    }

    if (this.subscription_type == "arc_essentials") {
      payload = {
        "object": this.username,
        "months": parseInt(this.months),
        "count": parseInt(this.count)
      }
    }

    this.api.post('/subscriptions/discount/', payload ).subscribe(
      data => {
        this.alertService.alert('success', 'Added discount to user email', 5);
        this.loader = false;
        this.refreshForm();
      }, error => {
        this.loader = false;
        this.alertService.alert('error', error['data']['detail'], 5);
      }
    );
  }

  // offline subscription
  enableFdatePicker()
  {
      (<any>$('#expire_date')).fdatepicker(
      {
          format: 'M dd, yyyy',
          onRender: (date: any) => {
            return date.valueOf() < new Date().valueOf() ? 'disabled' : '';
          }
      }).on('changeDate', (ev: any) =>
      {
        this.expire_date = moment($('#expire_date').val()).format("YYYY-MM-DD");
      });

      (<any>$('#expire_date_project')).fdatepicker(
      {
          format: 'M dd, yyyy',
          onRender: (date: any) => {
            return date.valueOf() < new Date().valueOf() ? 'disabled' : '';
          }
      }).on('changeDate', (ev: any) =>
      {
        this.expire_date_project = moment($('#expire_date_project').val()).format("YYYY-MM-DD");
      });

      (<any>$('#expire_date_project_ea')).fdatepicker(
      {
          format: 'M dd, yyyy',
          onRender: (date: any) => {
            return date.valueOf() < new Date().valueOf() ? 'disabled' : '';
          }
      }).on('changeDate', (ev: any) =>
      {
        this.expire_date_project_ea = moment($('#expire_date_project_ea').val()).format("YYYY-MM-DD");
      });
  };

  getUser(user: any, query: any)
  {
      var search = String(user.name) + " ("+ user.email +")";
      var replace = "<span class='fw-500'>"+ query +"</span>";
      var regEx = new RegExp(query, "ig");
      return search.replace(regEx, replace);
  };

  removeUser(index: any)
  {
      this.selected_users.splice(index, 1);
  };
  
  addUser(user: any)
  {
      this.selected_users.push({
          "name": user.name,
          "email": user.email,
      });
      this.isSearch(false);
  };

  isSearch(is_search: boolean)
  {
      this.is_search = is_search;
  };
  
  searchUser(search_user: any)
  {
    if (this.canceler) {
        this.canceler.unsubscribe();
    }
      
      if(search_user.length == 0)
      {
          this.search_results = [];
          return;
      }
      this.searching_user = true;
      this.canceler = this.api.get('/auth/user/search/?search=' + search_user).subscribe(
        data => {
          this.searching_user = false;
          this.search_results = data.results;
        },
        error => {
        }
      );
  };
  
  selectedUser(email: any)
  {
      var found = false;
      for(var n in this.selected_users)
      {
          if(this.selected_users[n].email == email)
          {
              found = true;
              break;
          }
      }
      return found;
  };

  addSubs()
  {
      var payload = {
          "mode": "free",
          "next_billing_date": moment($("#expire_date").val(), 'MMM DD, YYYY').format('YYYY-MM-DD'),
          "users": this.selected_users
      };
      this.loading = true;
      this.api.post('/subscriptions/offline/essentials/', payload ).subscribe(
        data => {
          this.loading = false;
          $("#expire_date").val('') 
          this.search_user = null;
          this.search_results = [];
          this.selected_users = [];
          this.alertService.alert('success', 'Subscription added to user(s)', 5);
        }, error => {
          this.loading = false;
          this.alertService.alert('error', 'Error addding subscription.', 5);
        }
      );
  };

  searchProject(leed_id: any)
  {
      if(this.selected_projects.indexOf(leed_id) != -1)
      {
          return;
      }
      this.selected_projects.push(leed_id);
      this.search_project = '';
  };

  removeProject(index: any)
  {
      this.selected_projects.splice(index, 1);
  };
  
  selectCheckbox(key: any, val: any)
  {
    if (key == 'mode_project'){
      this.mode_project = val;
    }
    else if (key == 'mode_user'){
      this.mode_user = val;
    }
  };

  addCert(save: any)
  {
      if(!save)
      {
          return;
      }
      var payload = {
          "mode": this.mode_project,
          "next_billing_date": moment($("#expire_date_project").val(), 'MMM DD, YYYY').format('YYYY-MM-DD'),
          "leed_ids": this.selected_projects
      };
      this.loading = true;
      this.api.post('/subscriptions/offline/performance/', payload ).subscribe(
        data => {
          this.loading = false;
          $("#expire_date_project").val('') 
          this.search_project = null;
          this.selected_projects = [];
          this.alertService.alert('success', 'Subscription added to user(s)', 5);
        }, error => {
          this.loading = false;
          this.alertService.alert('error', 'Error addding subscription.', 5);
        }
      );
  };

  addEASubs(save: any)
  {
      var payload = {
          "mode": "offline_paid",
          "next_billing_date": moment($("#expire_date_project_ea").val(), 'MMM DD, YYYY').format('YYYY-MM-DD'),
          "leed_ids": this.selected_projects
      };
      this.loading = true;
      this.api.post('/subscriptions/offline/efficiencyanalysis/', payload ).subscribe(
        data => {
          this.loading = false;
              this.expire_date_project_ea = null;
              this.search_project = null;
              this.selected_projects = [];
              this.alertService.alert('success', 'Subscription added to project(s)', 5);
        }, error => {
          this.loading = false;
          this.alertService.alert('error', 'Error addding subscription.', 5);
        }
      );
  };

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService,
    private validator: ValidationService
  ) { }

  ngOnInit(): void {
  }

}
