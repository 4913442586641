
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <h1 class="page-title pl0 pb10">Pre payment</h1>
        <div class="row">
            <div class="">
                <section class="widget">
                    <header>
                        <h5 class="mb10">Enter LEED ID to view the project. Please use this <a href="../../assets/pdf/Pre-payment.pdf" download>guide</a> to learn how to use this tool.</h5>
                    </header>
                    <div class="widget-body">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <div class="col-sm-10" class="form-group" [ngClass]="loader ? 'not-active' : ''">
                                        <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                        <div class="col-sm-7">
                                            <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control"
                                                placeholder="LEED ID" (input)="checkLEEDID()" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="wizard display-block">
                                            <button (click)="searchProject()" class="btn btn-default pull-left mr10"
                                                [ngClass]="checkLEED ? '' : 'not-active'"><i class="fa fa-search"
                                                    aria-hidden="true"></i> Search</button>
                                            <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <table class="table table-striped mt30" id="admin_pre_pay_table"
                            [ngClass]="not_active ? 'not-active' : ''">
                            <thead>
                                <tr>
                                    <th class="w20p">Project ID</th>
                                    <th class="w20p">Project Name</th>
                                    <th class="w20p">Payment Date</th>
                                    <th class="w20p">Bypass Payment Blocker</th>
                                    <th class="w20p">Bypass Area Blocker</th>
                                </tr>
                            </thead>
                            <tbody [hidden]="!bld_data.leed_id">
                                <tr>
                                    <td>{{bld_data.leed_id}}</td>
                                    <td>{{bld_data.name}}</td>
                                    <td>{{global.dateFormat(review_data.created_at)}}</td>
                                    <td class="align-center">
                                        <input class="cursor-pointer"
                                            (change)="makeAction(review_data, 'bypass_payment')" type="checkbox"
                                            name="bypass_payment" id=bypass_payment>
                                        <label for="bypass_payment"></label>
                                    </td>
                                    <td class="align-center">
                                        <input class="cursor-pointer" (change)="makeAction(review_data, 'bypass_area')"
                                            type="checkbox" name="bypass_area" id=bypass_area>
                                        <label for="bypass_area"></label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>