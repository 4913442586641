<app-sidebar></app-sidebar>

<div class="content-wrap">
	<div id="content" class="content">
		<div class="widget_container align-center pt16" *ngIf="!appData.get('is_admin')">
	
			<div *ngIf="appData.get('user_role') != 'arc_pro'" id="arc_premium_dark">
				<div class="flex">
					<div class="banner_content">
						<div class="align-left mr35">
							<div class="fs20 fw-500 line_height_20 mb8" translate>Upgrade to {{arc_pro_label}}</div>
							<div class="fs16 line_height_22" translate>Use advanced features to improve performance</div>
						</div>
						<div class="flex pt15">
							<button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.filterAccess('upgrade_arc', undefined, {'mode': 'purchase_arc_pro'})" translate>Get Essentials</button>
							<button (click)="global.learnMore('arc_pro')" class="btn btn-default inverse fs16 h40 br2p" translate>Learn more</button>
						</div>
					</div>
					<div class="banner_image">
						
					</div>
				</div>
			</div>
		
			<app-widget id="arcoverview" [src]="'arcoverview'" [param]="null" [width]="'85%'" [height]="'152px'"></app-widget>
			<app-widget *ngIf="appData.get('user_role') == 'arc_pro'" [src]="'arcnotification'" [param]="null" [width]="'56%'" [height]="'395px'" class="mr15"></app-widget>
			<app-widget *ngIf="appData.get('user_role') == 'arc_pro' && !appData.get('is_gsa_user')" [src]="'arcpartner'" [param]="'&partner=re_entry'" [width]="'28%'" [height]="'395px'"></app-widget>
			<app-widget *ngIf="appData.get('user_role') == 'arc_pro' && appData.get('is_gsa_user')" [src]="'arcpartner'" [param]="'&partner=ptz'" [width]="'28%'" [height]="'395px'"></app-widget>
		
			<div class="headings">
				<span class="widget_heading" translate>Score and Metrics</span>
				<div class="pt5">
					<span class="label" translate>How scores and metrics work</span>
					<sup *ngIf="appData.get('project_type') =='building' || appData.get('project_type') =='transit' || appData.get('project_type') == 'school'">
						<span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('total_leed_arc')"></span>
					</sup>
					<span class="label fw-500" style="color: #3D474F;" translate>Last 12 months</span>
				</div>
			</div>
		
			<app-widget [src]="'arcaveragescore'" [param]="null" [width]="'56%'" [height]="'395px'" class="mr15"></app-widget>
			<app-widget *ngIf="appData.get('user_role') == 'arc_pro'" [src]="'arcimprovementscore'" [param]="null" [width]="'28%'" [height]="'395px'"></app-widget>
			<app-widget *ngIf="appData.get('user_role') != 'arc_pro'" [src]="'plaque'" [param]="'&order_by=-total_score'" [width]="'28%'" [height]="'395px'"></app-widget>
			<app-widget *ngIf="appData.get('user_role') != 'arc_pro'" [src]="'arcpartner'" [param]="'&partner=certificate'" [width]="'28%'" [height]="'300px'" class="mr15"></app-widget>
			<app-widget *ngIf="appData.get('user_role') != 'arc_pro'" [src]="'arcpartner'" [param]="'&partner=arbnco'" [width]="'28%'" [height]="'300px'" class="mr15"></app-widget>
			<app-widget *ngIf="appData.get('user_role') != 'arc_pro'" [src]="'arcpartner'" [param]="'&partner=re_entry'" [width]="'28%'" [height]="'300px'"></app-widget>
			<app-widget [src]="'arcaverageemissions'" [param]="null" [width]="'56%'" [height]="'395px'" class="mr15"></app-widget>
			<app-widget [src]="'arcaverageemissionssource'" [param]="null" [width]="'28%'" [height]="'395px'"></app-widget>
			<app-widget [src]="'arcaveragecategoryusage'" [param]="null" [width]="'42%'" [height]="'395px'" class="mr15"></app-widget>
			<app-widget [src]="'arcaveragecategoryusage'" [param]="'&type=water'" [width]="'42%'" [height]="'395px'"></app-widget>
		</div>
	</div>
</div>