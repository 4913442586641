export const environment =
{
	production: false,
	ENV_FLAG: "DEV",

	subscription_key: '1c9444f6ca98459d9c1c9b6dc5c81898',
    basic_api_url   : 'https://api.usgbc.org/dev/leed',
    rating_systems  :  {
		building: 'LEED V4 O+M: EB WP',
		city: 'LEED-CT',
		community: 'LEED-CM',
		district: 'LEED-DT',
		neighborhood: 'LEED-NB',
		transit: 'LEED V4 O+M: TR',
		parksmart: 'PARKSMART',
		sites: 'SITES'
	},
    logout_url      : 'http://dev.arconline.io/arc-user-logout',
    LO_URL          : 'https://leedonline-dev.usgbc.org/',
    LOV2_URL        : 'https://leedonline.usgbc.org/Login.aspx',
    animation_url   : 'api-dev.arconline.io/',
    FRONTEND_BASE_URL: "https://api.usgbc.org/dev/leed",
    DEMO_FLAG       : false,
    LO_PROXY_URL    : "https://leedonline-fileproxy-dev.usgbc.org",
    landingPageURL   : 'http://dev.arconline.io/',
    usgbc_basic_url  : "https://dv2.usgbc.org/",
    insight_url      : "https://api.usgbc.org/skanska-dev",
    v1_applicable_date: '2019-11-05',
    it_subscription_key: 'eab7f75111d84773947fc04ed4b8c959',
    all_arc_material   : [3021, 3118, 3158, 3158, 3477, 3144, 3144, 3476, 3114, 3114, 3478, 3148, 3148, 3161, 3159, 3160, 3160, 3480, 3143, 3143, 3479, 3115, 3115, 3483, 3149, 3149, 3161, 3154, 3154, 3420, 3147, 3271, 3021, 3370, 3122, 3200, 3153, 3163, 3152, 3140, 3141, 3200, 3162, 3154, 3420, 2515, 3142, 3512, 3533, 3511, 3534, 3473, 3472, 3154, 3154, 3420, 3153, 3570, 2840],
    s3_bucket_url: 'https://dev.app.arconline.io',
    sso_client: "AVLRfANe8fy5UCqzeScs3f9D1W8Drh6u",
    auth0_url: "https://dev-login.usgbc.org/",
	auth0_domain: "dev-login.usgbc.org",
    accounts_page: "https://dev-accounts.usgbc.org/",
    HUB_URL: "https://hub.stg.hub.usgbc.net/home"
}
