import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-sync-project',
  templateUrl: './sync-project.component.html',
  styleUrls: ['./sync-project.component.css']
})
export class SyncProjectComponent implements OnInit {

  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  leed_id: any = null;

  checkInputsCert()
  {
      if(isNaN(this.leed_id) )
      {
          this.checkInputCert = false;
      }
      else
      {
          this.checkInputCert = true;
      }
  };
  
  syncProject()
  {
      this.loaderCert = true;
      this.api.get('/assets/LEED:'+ this.leed_id + '/syncproject/').subscribe(
        data => {
          this.loaderCert = false;
          this.alertService.alert('success', data.message, 5);
        },
        error => {
          this.loaderCert = false;
          this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
        }
      );
  }

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService
  ) { }

  ngOnInit(): void {
  }

}
