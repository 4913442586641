import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-admin-deactivate',
  templateUrl: './deactivate.component.html',
  styleUrls: ['./deactivate.component.css']
})
export class DeactivateComponent implements OnInit {
  leed_id: any = '';
  leed_ids: any = [];
  loader: boolean = false;
  status: any = {
    "success": [],
    "project_not_found": [],
    "already_deactivated": []
  };

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  refreshForms() {
    this.leed_id = "";
    this.leed_ids = [];
  };

  addIDS(leed_id: any) {
    if (this.leed_ids.indexOf(leed_id) != -1) {
      return;
    }
    this.leed_ids.push(leed_id);
    this.leed_id = "";
  };

  removeID(id: any) {
    var index = this.leed_ids.indexOf(id);
    if (index !== -1) {
      this.leed_ids.splice(index, 1);
    }
  };

  clearStatus() {
    this.status = {
      "success": [],
      "project_not_found": [],
      "already_deactivated": []
    };
  };

  deactivate() {
    var data =
    {
      "payload": this.leed_ids
    };

    if (this.leed_ids.length == 0) {
      this.alertService.alert('error', 'Enter LEED ID\'s', 5);
      return;
    }

    this.loader = true;
    this.clearStatus();
    this.api.post('/optask/deactivate/', data).subscribe(
      data => {
        this.loader = false;
        this.status = data;
        this.refreshForms();
        this.alertService.alert('success', 'Operational task complete.', 5);
      }, error => {
        this.loader = false;
        try {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  ngOnInit(): void {
    this.refreshForms();
  }

}
