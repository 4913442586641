import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from '../../services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from '../../services/validation.service';

// import * as stream from 'getstream';
// import * as moment from 'moment';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.css']
})
export class CancelSubscriptionComponent implements OnInit {
  formValidation: any = this.validator.regex;
  config = Config;
  user_email_get: string = '';
  user_data: any = {};


  constructor(
    public global: Global,
    private api: API,
    private alertService: alertService,
    public appData: AppData,
    private validator: ValidationService,
  ) { }

  check_get_user: boolean = false;
  get_loader: boolean = false;
  subs_canceled: boolean = false;
  leed_id: string = '';
  subscription_value: string = "Arc essentials";
  subscription_type: string = "arc_essentials"

  validateEmail(email: string) {
    if (this.formValidation.email.test(email)) {
      return true;
    }
    else {
      return false;
    }
  };

  validateLEEDId(leed_id: string) {
    if (this.formValidation["PrevCertProdId"].test(leed_id)) {
      return true;
    }
    else {
      return false;
    }
  };

  checkInputs() {
    if (this.subscription_type == "arc_essentials") {
      if (this.validateEmail(this.user_email_get)) {
        this.check_get_user = true;
      }
      else {
        this.check_get_user = false;
      }
    }
    else {
      if (this.validateLEEDId(this.leed_id)) {
        this.check_get_user = true;
      }
      else {
        this.check_get_user = false;
      }
    }
  };

  changeSubscriptionType(type: string, val: string) {
    this.subscription_type = type;
    this.subscription_value = val;
    this.user_data = {};
    this.checkInputs();
  };

  searchUser() {
    this.get_loader = true;
    if (this.subscription_type == "arc_essentials") {
      this.api.get('/auth/user/subscriptions/?username=' + this.user_email_get).subscribe(
        data => {
          this.user_data = data;
          this.subs_canceled = false;
          this.get_loader = false;
        },
        error => {
          this.get_loader = false;
          if (error.error) {
            this.alertService.alert('error', error.error, 5);
          }
          else {
            this.alertService.alert('error', 'Something went wrong.', 5);
          }
        }
      );
    }
    else {
      this.api.get('/assets/LEED:' + this.leed_id + '/subscriptions/performance/').subscribe(
        data => {
          this.user_data = data;
          this.subs_canceled = false;
          this.get_loader = false;
        },
        error => {
          this.get_loader = false;
          if (error.error) {
            this.alertService.alert('error', error.error, 5);
          }
          else {
            this.alertService.alert('error', 'Something went wrong.', 5);
          }
        }
      );
    }
  };

  action(action_name: string) {
    this.subs_canceled = false;
    this.get_loader = true;
    this.api.get('/subscriptions/' + this.user_data.subscription_id + '/' + action_name + '/').subscribe(
      data => {
        this.get_loader = false;
        this.subs_canceled = true;
        this.alertService.alert('success', data, 5);
      },
      error => {
        this.get_loader = false;
          if (error.error) {
            this.alertService.alert('error', error.error, 5);
          }
          else {
            this.alertService.alert('error', 'Something went wrong.', 5);
          }
      }
    );
  };


  ngOnInit(): void {

  }

}
