import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';

import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	arc_pro_label: string = Config.arc_pro_label;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API
	) 
	{}

	ngOnInit(): void
	{
		try
		{
			if(this.appData.getLocal('auth0').is_admin)
			{
				this.global.goToApp('projects');
			}
		}
		catch(e)
		{}
		
		this.api.get('/auth/usgbc/userprofile/').subscribe(
			data =>
			{},
			function(error)
			{}
		);
	}
}
