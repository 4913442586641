import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import * as moment from 'moment';

@Component({
  selector: 'app-recompute-analysisinfo',
  templateUrl: './recompute-analysisinfo.component.html',
  styleUrls: ['./recompute-analysisinfo.component.css']
})
export class RecomputeAnalysisinfoComponent implements OnInit {
  Object = Object;
  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  end_date: any = moment(new Date()).format("MMM DD, YYYY");
  scores_received: boolean = false;
  kpis: any = [];
  leed_id: any = '';

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  checkInputsCert()
  {
      if(isNaN(this.leed_id) || this.end_date == '')
      {
          this.checkInputCert = false;
      }
      else
      {
          this.checkInputCert = true;
      }
  };
  
  recomputeScore()
  {
      this.loaderCert = true;
      this.scores_received = false;

      var final_date: any = moment($('#end_date').val()).format("YYYY-MM-DD");

      var recompute_url = '/assets/LEED:'+ this.leed_id + '/analysisinfo-recompute/?type=energy_use_intensity,emissions_by_floor_area,source_energy_use_intensity';

      if (final_date != moment(new Date()).format("YYYY-MM-DD")){

          var selected_date: any = final_date.split('-');

          var new_date_obj = new Date(selected_date[0],parseInt(selected_date[1])-1,selected_date[2]);

          if (new_date_obj < new Date()){
              var recompute_end_date = String(new_date_obj.getFullYear()) + '-' + ('0' + String(new_date_obj.getMonth() + 1)).slice(-2) + '-' + ('0' + String(new_date_obj.getDate())).slice(-2);
              recompute_url = '/assets/LEED:'+ this.leed_id + '/analysisinfo-recompute/?type=energy_use_intensity,emissions_by_floor_area&end_date=' + recompute_end_date;
          }
      }

      this.api.get(recompute_url).subscribe(
        data => {
          this.kpis = data;
          this.loaderCert = false;
          this.scores_received = true;
        },
        error => {
          this.loaderCert = false;
          this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
        }
      );
  }
  
  enableFdatePicker()
  {
      (<any>$('#end_date')).fdatepicker(
      {
          format: 'M dd, yyyy',
          onRender: function (date: any) 
          {
              return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
          }
      });
  }

  ngOnInit(): void {
  }

}
