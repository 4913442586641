<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="admin_certification_body">
            <h3 class="certificationWrapper">Update Owner<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb10 pt30">Please provide the details below to update the owner details. Please use this <a href="../../assets/pdf/Owner-Info.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <input (input)="checkInputs()" [(ngModel)]="leed_id" name="leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Owner Organization</label>
                                <div class="col-sm-7" [ngClass]="formdata_error.organization.error ? 'has-error' : ''">
                                <!-- <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">   -->
                                    <input name="organization" id="organization" class="form-control" (input)="searchOrganization(formdata.organization)" [(ngModel)]="formdata.organization" type="text" required autocomplete="off">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <div *ngIf="formdata_error.organization.error" class="mt8 pl5" style="color: #707479;"><span (click)="openModal()" class="cursor-pointer">Can't find your organization? Create a <a>new organization</a></span></div>
                                    <span class="error">{{formdata_error.organization.help}}</span>
                                    <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization">
                                        <div *ngFor="let organization of all_organizations" class="projectPort_row" (click)="selectOrganization(organization);">
    
                                            <div class="cursor-pointer vertical_align_middle">          
                                                <span>{{organization}}</span>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Owner Email</label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <input (input)="checkInputs()" [(ngModel)]="owner_email" name="owner_email" type="text" id="normal-field" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Owner Representative</label>
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <input (input)="checkInputs()" [(ngModel)]="owner_name" name="owner_name" type="text" id="normal-field" class="form-control">
                                </div>
                            </div>
                            
                            <div class="form-group">   
                                <label class="col-sm-3 control-label" for="prepended-input">Owner Type</label>   
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <select [(ngModel)]="owner_type" id="ownerType" name="ownerType" class="form-control">
                                        <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Owner Country/Region</label> 
                                <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                    <select name="manageEntityCountry" [(ngModel)]="formdata.manageEntityCountry" class="form-control" data-parsley-trigger="change">
                                        <option *ngFor="let country of countries" [value]="country.code" >{{country.name}}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <label class="col-sm-3 control-label" for="prepended-input"></label>
                            <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                <button (click)="saveOwnerInfo()" [ngClass]="checkInput ? '' : 'not-active'" class="btn btn-default pull-left mr10">
                                    <i class="icon-save" aria-hidden="true"></i> Save</button>
                                <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div> 
    </div>
</div>

<modal #modal>
    <app-organization (close_data)="closeModal()" (success_data)="getDataFromModal($event)"></app-organization>
</modal>
