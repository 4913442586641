
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <h1 class="page-title pl0 pb10">Update CAGBC invoice</h1>
        <div class="row">
            <div class="">
                <section class="widget mb15">
                    <div class="widget-body mt20">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <div class="col-sm-10" class="form-group" [ngClass]="loader ? 'not-active' : ''">
                                        <label class="col-sm-2 control-label">Project ID</label>
                                        <div class="col-sm-7">
                                            <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control"
                                                placeholder="LEED ID" (input)="checkLEEDID()" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="wizard display-block">
                                            <button (click)="searchProject()" class="btn btn-default pull-left mr10"
                                                [ngClass]="checkLEED ? '' : 'not-active'"><i class="fa fa-search"
                                                    aria-hidden="true"></i> Search</button>
                                            <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                        </div>
                                    </div>
                                    <div class="form-group mb0 mt30">
                                        <div class="pl30">
                                            * This tool should be used only after CAGBC receives a payment for recertification.
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </section>
                <section class="widget" [hidden]="!show_details">
                    <div class="widget-body mt20">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Project Name</label>
                                    <div class="col-sm-7">
                                        <input [(ngModel)]="bld_data.name" class="form-control" [disabled]="true"  name="project_name" type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Owner Organization</label>
                                    <div class="col-sm-7">
                                        <input [(ngModel)]="bld_data.organization" class="form-control" [disabled]="true"  name="project_organization" type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Order Number</label>
                                    <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                        <input (input)="checkInputs('order_number')" [(ngModel)]="order_number" name="order_number" type="text" id="order_number" class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Order Date</label>
                                    <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                        <input (input)="checkInputs('order_date')" id="order_date" [(ngModel)]="order_date" name="order_date" class="col-sm-7 form-control" [ngInit]="enableFdatePicker()">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Order Status</label>
                                    <div class="dropdown display-inline ml10" [ngClass]="loader ? 'not-active' : ''">
                                        <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                        <span>Completed</span>
                                        <span class="caret ml8"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li class="energy_unit"><a>Completed</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Amount (CAD)</label>
                                    <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                        <input [inputType]="'float'" (input)="checkInputs('amount')" [(ngModel)]="amount" name="amount" type="text" id="amount" class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Upload Invoice</label>
                                    <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                        <input #elem (input)="nameChanged(elem)" class="hide uploadFile" type="file">
                                        <button (click)="global.openUploadDialog('laptop', '', 'S3')" class="btn btn-default btn-sm">
                                            <i class="far fa-file-pdf mr10"></i>
                                            Select a file
                                        </button>
                                        <span *ngIf="pdfUploadData != undefined" class="fw-600 fontSize13 ml10 pt5">{{pdfUploadName}}</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label" for="prepended-input"></label>
                                    <div class="col-sm-7">
                                        <button (click)="saveDetails()" [ngClass]="validation_error ? 'not-active' : ''" class="btn btn-default pull-left mr10">
                                            <i class="icon-save" aria-hidden="true"></i> Save
                                        </button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </section>
                <section class="widget" [hidden]="!no_review">
                    <div class="widget-body align-center fs15">
                        No review found.
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updated_successfully" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">Updated successfully</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>