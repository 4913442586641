<div class="sidebar_nav" (click)="global.toggleMenu()">
	<div class="side_nav_option align_lr">
		<div class="appLogoBlack"></div>
		<svg _ngcontent-wgn-c114="" width="24" height="24" viewBox="0 0 24 24" class="svg_hover"><path _ngcontent-wgn-c114="" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path _ngcontent-wgn-c114="" d="M0 0h24v24H0z" fill="none"></path></svg>
	</div>
	<div class="side_nav_option" translate [ngClass]="appData.get('current_nav') == 'app/home/overview' ? 'active': ''" (click)="goTo('home')">Home</div>
	<div class="side_nav_option" translate [ngClass]="appData.get('current_nav') == 'app/projects/my-projects' || appData.get('current_app') == 'selected_project' ? 'active': ''" (click)="goTo('projects')">Projects</div>
	<div class="side_nav_option"  translate [ngClass]="appData.get('current_app') == 'portfolios' ? 'active': ''" (click)="goTo('portfolios')">Portfolios</div>
</div>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm white" *ngIf="sidebar_type == 'projects'">
	<div class="js-sidebar-content" [ngClass]="loading ? 'not-active' : ''">
		<app-loader *ngIf="loading"></app-loader>
        <div class="project_list_options" *ngIf="!loading">
            
			<div class="option" [ngClass]="appData.get('project_type') == 'all' && appData.get('certified') == null ? 'active': ''" (click)="changeProjectType('all')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-3h3.56c.69 1.19 1.97 2 3.45 2s2.75-.81 3.45-2H19v3zm0-5h-4.99c0 1.1-.9 2-2 2s-2-.9-2-2H5V5h14v9z"/></svg>
				<span class="ml10" translate>All</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.total_projects)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'building' ? 'active': ''" (click)="changeProjectType('building')">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path d="M0 0h24v24H0z" fill="none"/>
					<path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/>
				</svg>
				<span class="ml10" translate>Buildings</span>
				<span  [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.building_project)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'transit' ? 'active': ''" (click)="changeProjectType('transit')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><circle cx="8.5" cy="14.5" r="1.5"/><circle cx="15.5" cy="14.5" r="1.5"/><path d="M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h2l2-2h4l2 2h2v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4zm0 2c3.51 0 4.96.48 5.57 1H6.43c.61-.52 2.06-1 5.57-1zM6 7h5v3H6V7zm12 8.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5V12h12v3.5zm0-5.5h-5V7h5v3z"/></svg>
				<span class="ml10" translate>Transit</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.transit_projects)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'parksmart' ? 'active': ''" (click)="changeProjectType('parksmart')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.08 3.11H5.77L6.85 7zM19 17H5v-5h14v5z"/><circle cx="7.5" cy="14.5" r="1.5"/><circle cx="16.5" cy="14.5" r="1.5"/></svg>
				<span class="ml10" translate>Parking</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.parksmart_project)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'city' ? 'active': ''" (click)="changeProjectType('city')">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"/>
					<path d="M0 0h24v24H0z" fill="none"/>
				</svg>
				<span class="ml10" translate>Cities</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.city_projects)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'community' ? 'active': ''" (click)="changeProjectType('community')">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path d="M0 0h24v24H0z" fill="none"/>
					<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
				</svg>
				<span class="ml10" translate>Communities</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.community_projects)}}</span>
			</div>
			<div class="option" [ngClass]="appData.get('project_type') == 'schools' ? 'active': ''" (click)="changeProjectType('schools')">
				<svg version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
					<g id="Bounding_Box">
						<rect fill="none" width="24" height="24"/>
					</g>
					<g id="Master">
						<path d="M19,9.3V4h-3v2.6L12,3L2,12h3v8h5v-6h4v6h5v-8h3L19,9.3z M10,10c0-1.1,0.9-2,2-2s2,0.9,2,2H10z"/>
					</g>
				</svg>
				<span class="ml10" translate>Schools</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.school_projects)}}</span>
			</div>
			<div class="divider"></div>
			<div class="option" [ngClass]="appData.get('project_type') == 'certified' ? 'active': ''" (click)="changeProjectType('certified')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 1H5c-1.1 0-1.99.9-1.99 2L3 15.93c0 .69.35 1.3.88 1.66L12 23l8.11-5.41c.53-.36.88-.97.88-1.66L21 3c0-1.1-.9-2-2-2zm-7 19.6l-7-4.66V3h14v12.93l-7 4.67zm-2.01-7.42l-2.58-2.59L6 12l4 4 8-8-1.42-1.42z"/></svg>
				<span class="ml10" translate>Certified</span>
				<span [hidden]="appData.get('is_admin')" class="floatr">{{global.addCommas(asset_analytics.certified_projects)}}</span>
			</div>

        </div>
    </div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar grey" *ngIf="sidebar_type == 'project' && appData.get('app_name') != 'payment' && appData.get('app_name') != 'review-selection' && appData.get('app_name') != 'review-payment' && appData.get('app_name') != 'purchase-certificates' && appData.get('app_name') != 'create-models' && appData.get('app_name') != 'survey' && appData.get('app_name') != 're-entry-survey'">
    <div class="js-sidebar-content">
        <div  class="project_list_options">
            <div *ngIf="appData.get('project_type') != 'parksmart'">
				<ul class="sidebar-nav mb0" *ngIf="appData.get('new_arc_project') && !global.isLEEDCertified(appData.get('buildingData').certification)">
					<li>
						<a class=" sidebar-projects-link collapsed" data-target="#sidebar-actions" data-toggle="collapse" data-parent="#sidebar" id="sidebar-projects-link">
							<i class="fa fa-list fontSize14"></i>
							<span class="ml10" translate>Credits/Actions</span>
							<i class="toggle fa fa-angle-down"></i>
						</a>
						<ul id="sidebar-actions" class="collapse">
							<li (click)="global.filterTrial('actions', {type: 'allactions'})" [ngClass]="global.getURLFromParam('type') == 'allactions' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									All Actions
								</a>
							</li>
							<li (click)="global.filterTrial('actions', {type: 'prerequisites'})" [ngClass]="global.getURLFromParam('type') == 'prerequisites' ? 'active': ''" class="option pl45">
								<a class="pl50" [ngClass]="appData.get('project_type') =='building' || appData.get('project_type') =='transit' || appData.get('project_type') =='school' ? 'show' : 'hide'" id="ca_prerequisites" >Prerequisites
								</a>
								
								<a class="pl50" [ngClass]="appData.get('project_type') !='building' && appData.get('project_type') !='transit' && appData.get('project_type') != 'school' ? 'show' : 'hide'" id="ca_prerequisites" >PreCertification</a>
							</li>
							<li (click)="global.filterTrial('actions', {type: 'basepoints'})" [ngClass]="global.getURLFromParam('type') == 'basepoints' ? 'active': ''" class="option pl45" *ngIf="appData.get('new_arc_project')" (hidden)="!(appData.get('project_type') =='building' || appData.get('project_type') =='transit' || appData.get('project_type') =='school')">
								<a class="pl50" id="ca_basepoints">Base Points
								</a>
							</li>
							<li (click)="global.filterTrial('actions', {type: 'cc_basepoints'})" [ngClass]="global.getURLFromParam('type') == 'cc_basepoints' ? 'active': ''" class="option pl45" *ngIf="appData.get('new_arc_project') && (appData.get('project_type') == 'city' || appData.get('project_type') == 'community')">
								<a class="pl50" id="cc_basepoints">Base Points
								</a>
							</li>
							<li (click)="global.filterTrial('actions', {type: 'assignedtome'})" [ngClass]="global.getURLFromParam('type') == 'assignedtome' ? 'active': ''" class="option pl45">
								<a class="pl50" id="ca_assignedtome">My Actions
								</a>
							</li>
						</ul>
					</li>
				</ul>
			
				<div (click)="changeApp('project-overview')" *ngIf="!(appData.get('project_type') == 'city' || appData.get('project_type') == 'community' || appData.get('project_type') == 'transit')" class="option" [ngClass]="appData.get('app_name') == 'project-overview' ? 'active': ''">
					<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"/></svg>
					<span class="ml10" translate>Overview</span>
				</div>
				<div (click)="changeApp('project-performance')" *ngIf="!(appData.get('project_type') == 'transit')" class="option" [ngClass]="appData.get('app_name') == 'project-performance' ? 'active': ''">
					<svg width="30" height="30" viewBox="0 0 30 30">
					<g fill="none" fill-rule="evenodd">
						<g fill="#000" transform="translate(3 7)">
						<path d="M12.4400831,5.94889812 C13.8220903,5.94985628 14.9406176,7.1667199 14.9406176,8.4976046 C14.9406176,9.83902906 13.8200713,10.9313318 12.4370546,10.9313318 C11.932304,10.9313318 11.1247031,10.6343021 10.7209026,10.3650591 C10.5593824,10.3650591 9.24703088,10.5193229 9.24703088,10.5193229 C9.24703088,10.5193229 9.32779097,11.3337592 9.40855107,11.8511658 C10.236342,12.5889492 11.3064133,13 12.4370546,13 C14.9507126,13 17,10.9888215 17,8.4976046 C17,6.0073459 14.9527316,4.0047908 12.4400831,4.00383264 L0,4 L0,5.95560524 L12.4400831,5.94889812 Z"/>
						<path d="M18.9515991,8.5 C18.9515991,12.0822277 16.0432427,14.9818436 12.460252,14.9818436 C10.8389211,14.9818436 9.31396648,14.3872789 8.13034472,13.3297253 C6.97684056,12.2978934 6.22992403,10.8980447 6.02612825,9.36266294 C5.80626976,9.18261173 4.88366721,8.5 4.88366721,8.5 C4.88366721,8.5 4.22208389,9.25878724 3.97813131,9.50215317 C4.22208389,11.5608706 5.20994124,13.4444832 6.7539703,14.8245461 C8.31004641,16.2164804 10.324914,17 12.460252,17 C17.1796802,17 21.0006,13.1922137 21.0006,8.5 C21.0006,3.80877561 17.1796802,0 12.460252,0 L0.0006,0 L0.0006,2.01914572 L12.460252,2.01914572 C16.0432427,2.01914572 18.9515991,4.91777235 18.9515991,8.5"/>
						</g>
						<path fill="#000" d="M15.5,2.9999 L3,2.9999 L3,5.0409 L15.5,5.0409 C21.276,5.0409 25.959,9.7239 25.959,15.4999 C25.959,21.2759 21.276,25.9589 15.5,25.9589 C12.886,25.9589 10.424,24.9979 8.518,23.2879 C7.829,22.6699 7.225,21.9629 6.721,21.1879 C6.262,20.4799 5.613,18.9229 5.613,18.9229 L4.254,18.3109 L3.712,19.6339 C3.712,19.6339 4.428,21.4029 5.009,22.2999 C5.611,23.2259 6.333,24.0689 7.155,24.8069 C9.432,26.8499 12.378,27.9999 15.5,27.9999 C22.403,27.9999 28,22.4039 28,15.4999 C28,8.5969 22.403,2.9999 15.5,2.9999"/>
					</g>
					</svg>
					<span class="ml10" translate>Performance</span>
				</div>
			
				<div (click)="changeApp('data-input')" class="option" [ngClass]="appData.get('app_name') == 'data-input' ? 'active': ''">
					<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20.38 8.57l-1.23 1.85a8 8 0 0 1-.22 7.58H5.07A8 8 0 0 1 15.58 6.85l1.85-1.23A10 10 0 0 0 3.35 19a2 2 0 0 0 1.72 1h13.85a2 2 0 0 0 1.74-1 10 10 0 0 0-.27-10.44zm-9.79 6.84a2 2 0 0 0 2.83 0l5.66-8.49-8.49 5.66a2 2 0 0 0 0 2.83z"/></svg>
					<span *ngIf="!(appData.get('project_type') == 'building' || appData.get('project_type') == 'school' || appData.get('project_type') == 'transit')" class="ml10" >Data</span>
					<span *ngIf="!(appData.get('project_type') == 'city' || appData.get('project_type') == 'community')" class="ml10">Data</span>
				</div>
			
				<div *ngIf="appData.get('project_type') == 'transit'" (click)="changeApp('project-performance')" class="option" [ngClass]="appData.get('app_name') == 'project-performance' ? 'active': ''">
					<svg width="30" height="30" viewBox="0 0 30 30">
					<g fill="none" fill-rule="evenodd">
						<g fill="#000" transform="translate(3 7)">
						<path d="M12.4400831,5.94889812 C13.8220903,5.94985628 14.9406176,7.1667199 14.9406176,8.4976046 C14.9406176,9.83902906 13.8200713,10.9313318 12.4370546,10.9313318 C11.932304,10.9313318 11.1247031,10.6343021 10.7209026,10.3650591 C10.5593824,10.3650591 9.24703088,10.5193229 9.24703088,10.5193229 C9.24703088,10.5193229 9.32779097,11.3337592 9.40855107,11.8511658 C10.236342,12.5889492 11.3064133,13 12.4370546,13 C14.9507126,13 17,10.9888215 17,8.4976046 C17,6.0073459 14.9527316,4.0047908 12.4400831,4.00383264 L0,4 L0,5.95560524 L12.4400831,5.94889812 Z"/>
						<path d="M18.9515991,8.5 C18.9515991,12.0822277 16.0432427,14.9818436 12.460252,14.9818436 C10.8389211,14.9818436 9.31396648,14.3872789 8.13034472,13.3297253 C6.97684056,12.2978934 6.22992403,10.8980447 6.02612825,9.36266294 C5.80626976,9.18261173 4.88366721,8.5 4.88366721,8.5 C4.88366721,8.5 4.22208389,9.25878724 3.97813131,9.50215317 C4.22208389,11.5608706 5.20994124,13.4444832 6.7539703,14.8245461 C8.31004641,16.2164804 10.324914,17 12.460252,17 C17.1796802,17 21.0006,13.1922137 21.0006,8.5 C21.0006,3.80877561 17.1796802,0 12.460252,0 L0.0006,0 L0.0006,2.01914572 L12.460252,2.01914572 C16.0432427,2.01914572 18.9515991,4.91777235 18.9515991,8.5"/>
						</g>
						<path fill="#000" d="M15.5,2.9999 L3,2.9999 L3,5.0409 L15.5,5.0409 C21.276,5.0409 25.959,9.7239 25.959,15.4999 C25.959,21.2759 21.276,25.9589 15.5,25.9589 C12.886,25.9589 10.424,24.9979 8.518,23.2879 C7.829,22.6699 7.225,21.9629 6.721,21.1879 C6.262,20.4799 5.613,18.9229 5.613,18.9229 L4.254,18.3109 L3.712,19.6339 C3.712,19.6339 4.428,21.4029 5.009,22.2999 C5.611,23.2259 6.333,24.0689 7.155,24.8069 C9.432,26.8499 12.378,27.9999 15.5,27.9999 C22.403,27.9999 28,22.4039 28,15.4999 C28,8.5969 22.403,2.9999 15.5,2.9999"/>
					</g>
					</svg>
					<span class="ml10" translate>Performance</span>
				</div>
			
				<div *ngIf="appData.get('project_type') == 'city' || appData.get('project_type') == 'community' || appData.get('project_type') == 'transit'" class="option" [ngClass]="appData.get('app_name') == 'certifications' ? 'active': ''" (click)="global.filterTrial('certifications', {project_id: appData.get('leed_id')})">
					<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 1H5c-1.1 0-1.99.9-1.99 2L3 15.93c0 .69.35 1.3.88 1.66L12 23l8.11-5.41c.53-.36.88-.97.88-1.66L21 3c0-1.1-.9-2-2-2zm-7 19.6l-7-4.66V3h14v12.93l-7 4.67zm-2.01-7.42l-2.58-2.59L6 12l4 4 8-8-1.42-1.42z"/></svg>
					<span class="ml10" translate>Certifications</span>
				</div>
			
				<!-- <div (click)="changeApp('models')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school') && appData.get('buildingData').score_code_version.name >= 2" class="option" [ngClass]="appData.get('app_name') == 'models' ? 'active': ''">
					<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
					<span class="ml10" translate>Models</span>
				</div> -->

				<ul class="sidebar-nav mb0 pb0" *ngIf="!(appData.get('project_type') == 'city' || appData.get('project_type') == 'community' || appData.get('project_type') == 'transit')">
					<li>
						<a class="option sidebar-models-link" data-target="#sidebar-models" data-toggle="collapse" data-parent="#sidebar" id="sidebar-models-link">
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
							<span class="ml10" translate>Models</span>
							<i class="toggle fa fa-angle-down"></i>
						</a>
						<ul id="sidebar-models" class="collapse">
							<li [ngClass]="appData.get('app_name') == 'models' && appData.get('insight_app') == null ? 'active': ''" (click)="changeApp('models')" class="option pl45">
								<a class="pl50" translate>
									LEED O+M score modeling
								</a>
							</li>

							<ul class="sidebar-nav">
								<li>
									<a (click)="insight.openInsights()" [ngClass]="['home', 'market', 'model'].indexOf(appData.get('insight_app')) != -1 ? 'active': ''" class="option sidebar-insight-link" data-target="#sidebar-insight" data-toggle="collapse_in" data-parent="#sidebar-insight" id="sidebar-insight-link" style="padding-left: 45px !important;margin-left: 10px;">
										<span translate>LEED BD+C Insight</span>
										<span *ngIf="['home', 'market', 'model', null].indexOf(appData.get('insight_app')) == -1">
											<i class="toggle fa fa-angle-down"></i>
										</span>
									</a>
									<ul [hidden]="['home', 'market', 'model', null].indexOf(appData.get('insight_app')) != -1" id="sidebar-insight" class="collapse_in pl45">
										<li (click)="setInsightComponent('reduction')" [ngClass]="appData.get('insight_app') == 'reduction' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												Reduction
											</a>
										</li>
										<li (click)="setInsightComponent('strategies')" [ngClass]="appData.get('insight_app') == 'strategies' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												Strategies
											</a>
										</li>
										<li (click)="setInsightComponent('comparison')" [ngClass]="appData.get('insight_app') == 'comparison' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												Comparisons
											</a>
										</li>
										<li (click)="setInsightComponent('zepi-score')" [ngClass]="appData.get('insight_app') == 'zepi-score' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												How scoring works
											</a>
										</li>
										<li (click)="setInsightComponent('manage')" [ngClass]="appData.get('insight_app') == 'manage' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												Manage Model
											</a>
										</li>
										<li (click)="setInsightComponent('team')" [ngClass]="appData.get('insight_app') == 'team' ? 'active': ''" class="option pl45">
											<a class="pl50" translate>
												Model Access
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</ul>
					</li>
				</ul>
			
				<div (click)="changeApp('project-tools-home')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" [ngClass]="appData.get('app_name') == 'project-tools-home' ? 'active': ''" class="option">
					<svg enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/><path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/><path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/></g></g><g display="none"><g display="inline"/><g display="inline"><path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/><path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/><path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/></g></g></svg>
					<span class="ml10" translate>Project Tools</span>
				</div>
				<div (click)="changeApp('advanced-scoring')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'advanced-scoring' ? 'active': ''">
					<span class="" translate>Advanced Scoring</span>
				</div>
				<div (click)="changeApp('performance-certificates')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'performance-certificates' ? 'active': ''">
					<span class="" translate>Performance Certificates</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'climate-risk' ? 'active': ''" (click)="changeApp('climate-risk')">
					<span class="" translate>Climate Risk</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school') && appData.get('space_type_gsa').indexOf(appData.get('buildingData').spaceType) > -1" class="pl56 option" [ngClass]="appData.get('app_name') == 'play-to-zero' ? 'active': ''" (click)="changeApp('play-to-zero')">
					<span class="" translate>Play to Zero</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school') && (appData.get('buildingData').country == 'US' || appData.get('buildingData').country == 'CA')" class="pl56 option" [ngClass]="appData.get('app_name') == 'financial-models' ? 'active': ''" (click)="changeApp('financial-models')">
					<span class="" translate>Financial Models</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 're-entry' ? 'active': ''" (click)="changeApp('re-entry')">
					<span class="" translate>Re-Entry</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'certifications' ? 'active': ''" (click)="changeApp('certifications')">
					<span class="" translate>LEED Certifications</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'eu-taxonomy' ? 'active': ''" (click)="changeApp('eu-taxonomy')">
					<span class="">EU Taxonomy</span>
				</div>
				<div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="pl56 option" [ngClass]="appData.get('app_name') == 'efficiency-analysis' ? 'active': ''" (click)="changeApp('efficiency-analysis')">
					<span class="" translate>Efficiency Analysis</span>
				</div>
			
				<div (click)="changeApp('reports')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="option" [ngClass]="appData.get('app_name') == 'reports' ? 'active': ''">
					<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5 9.2h3V19H5V9.2zM10.6 5h2.8v14h-2.8V5zm5.6 8H19v6h-2.8v-6z"/></svg>
					<span class="ml10" translate>Reports</span>
				</div>
			
				<ul class="sidebar-nav">
					<li>
						<a class="option sidebar-projects-link collapsed" data-target="#sidebar-projects" data-toggle="collapse" data-parent="#sidebar" id="sidebar-projects-link">
							<svg version="1.1" id="Layer_1" width="24" height="24" viewBox="0 0 28 28">
							<g id="_x36_68-gear4.png">
								<g>
								<path d="M14,9c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S16.762,9,14,9z M14,17c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3
								S15.657,17,14,17z M25.832,10.181l-1.18-0.295c-0.065-0.172-0.137-0.343-0.211-0.512l0.627-1.046c0.676-1.124,0.5-2.56-0.43-3.489
								L23.16,3.359c-0.54-0.54-1.258-0.837-2.02-0.837c-0.52,0-1.029,0.142-1.475,0.41l-1.041,0.624
								c-0.169-0.074-0.341-0.146-0.514-0.212l-0.295-1.18C17.497,0.89,16.357,0,15.045,0h-2.09c-1.314,0-2.454,0.891-2.772,2.165
								l-0.295,1.18C9.715,3.412,9.543,3.482,9.373,3.558L8.33,2.932C7.883,2.665,7.375,2.525,6.86,2.525
								c-0.763,0-1.479,0.296-2.019,0.833L3.361,4.84c-0.928,0.929-1.105,2.363-0.43,3.49l0.627,1.043
								c-0.075,0.17-0.146,0.342-0.213,0.515l-1.181,0.295c-1.275,0.319-2.164,1.459-2.164,2.771L0,15.044
								c0,1.313,0.889,2.453,2.161,2.772l1.185,0.297c0.066,0.173,0.137,0.344,0.212,0.513l-0.626,1.045
								c-0.677,1.125-0.5,2.562,0.433,3.491l1.474,1.476c0.541,0.541,1.259,0.838,2.021,0.838c0.518,0,1.026-0.141,1.471-0.407
								l1.043-0.626c0.17,0.075,0.341,0.146,0.514,0.212l0.295,1.181C10.5,27.109,11.639,28,12.953,28h2.092
								c1.314,0,2.455-0.891,2.772-2.165l0.295-1.181c0.173-0.066,0.345-0.137,0.515-0.212l1.043,0.626
								c0.443,0.267,0.952,0.407,1.47,0.407c0.762,0,1.479-0.296,2.018-0.833l1.482-1.484c0.929-0.926,1.106-2.362,0.428-3.491
								l-0.626-1.042c0.074-0.169,0.146-0.34,0.212-0.513l1.182-0.295C27.11,17.497,28,16.357,28,15.045v-2.091
								C28,11.639,27.109,10.498,25.832,10.181z M26,15.045c0,0.394-0.269,0.736-0.649,0.832l-1.776,0.443
								c-0.288,0.072-0.519,0.287-0.609,0.57c-0.156,0.487-0.352,0.956-0.582,1.406c-0.134,0.264-0.123,0.579,0.029,0.833l0.941,1.568
								c0.203,0.337,0.149,0.77-0.129,1.047l-1.479,1.48c-0.166,0.165-0.385,0.25-0.606,0.25c-0.151,0-0.304-0.04-0.44-0.122
								l-1.568-0.941c-0.137-0.081-0.289-0.122-0.442-0.122c-0.134,0-0.268,0.031-0.391,0.094c-0.45,0.23-0.919,0.426-1.407,0.582
								c-0.281,0.09-0.498,0.321-0.57,0.608l-0.443,1.776C15.782,25.732,15.439,26,15.045,26h-2.092c-0.393,0-0.736-0.268-0.831-0.649
								l-0.444-1.776c-0.07-0.288-0.287-0.519-0.568-0.608c-0.488-0.156-0.958-0.352-1.408-0.582c-0.122-0.062-0.256-0.094-0.391-0.094
								c-0.152,0-0.307,0.041-0.441,0.122l-1.568,0.941c-0.137,0.082-0.289,0.122-0.441,0.122c-0.221,0-0.44-0.086-0.605-0.251
								l-1.479-1.479c-0.277-0.277-0.33-0.71-0.129-1.046l0.941-1.569c0.152-0.254,0.164-0.568,0.027-0.832
								c-0.23-0.45-0.424-0.92-0.581-1.407c-0.091-0.282-0.321-0.497-0.608-0.569l-1.777-0.444C2.269,15.781,2,15.438,2,15.045
								l0.001-2.091c0-0.393,0.268-0.735,0.649-0.831l1.775-0.443c0.287-0.072,0.518-0.287,0.608-0.569
								c0.157-0.488,0.352-0.959,0.582-1.408c0.135-0.264,0.124-0.58-0.028-0.833L4.646,7.301C4.445,6.964,4.498,6.532,4.775,6.254
								l1.479-1.479c0.165-0.165,0.385-0.25,0.606-0.25c0.151,0,0.304,0.039,0.44,0.121l1.568,0.941C9.004,5.669,9.158,5.71,9.311,5.71
								c0.135,0,0.268-0.031,0.391-0.094c0.45-0.23,0.92-0.426,1.408-0.582c0.281-0.09,0.498-0.321,0.57-0.608l0.443-1.776
								C12.219,2.268,12.561,2,12.955,2h2.09c0.394,0,0.736,0.268,0.832,0.649l0.443,1.776c0.072,0.288,0.287,0.519,0.57,0.608
								c0.486,0.156,0.957,0.351,1.406,0.581c0.122,0.062,0.256,0.094,0.391,0.094c0.152,0,0.306-0.041,0.441-0.122l1.57-0.941
								c0.137-0.083,0.289-0.123,0.441-0.123c0.221,0,0.439,0.086,0.605,0.251l1.479,1.479c0.278,0.278,0.332,0.71,0.129,1.047
								l-0.941,1.57c-0.153,0.254-0.164,0.568-0.029,0.832c0.23,0.449,0.426,0.92,0.582,1.406c0.091,0.281,0.321,0.498,0.609,0.569
								l1.776,0.444C25.731,12.217,26,12.561,26,12.954V15.045z"/>
								</g>
							</g>
							</svg>
							<span class="ml10" translate>Manage</span>
							<i class="toggle fa fa-angle-down"></i>
						</a>
						<ul id="sidebar-projects" class="collapse">
							<li (click)="changeApp('project')" [ngClass]="appData.get('app_name') == 'project' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Project
								</a>
							</li>
							<li (click)="changeApp('team')" [ngClass]="appData.get('app_name') == 'team' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Team
								</a>
							</li>
							<li [hidden]="appData.get('project_type') == 'city' || appData.get('project_type') == 'community'" (click)="changeApp('apps')" [ngClass]="appData.get('app_name') == 'apps' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Integration Partners
								</a>
							</li>
							<li (click)="changeApp('billing')" [ngClass]="appData.get('app_name') == 'billing' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Billing
								</a>
							</li>
							<li (click)="changeApp('agreement')" [ngClass]="appData.get('app_name') == 'agreement' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Agreements
								</a>
							</li>
							<li (click)="changeApp('settings')" [ngClass]="appData.get('app_name') == 'settings' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Settings
								</a>
							</li>
							<li (click)="changeApp('score-version')" [ngClass]="appData.get('app_name') == 'score-version' ? 'active': ''" class="option pl45">
								<a class="pl50" translate>
									Score Version
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div *ngIf="appData.get('project_type') == 'parksmart'">
				<ul class="sidebar-nav pb0 mb0">
					<li>
						<a class="option sidebar-projects-link collapsed" data-target="#sidebar-actions" data-toggle="collapse" data-parent="#sidebar" id="sidebar-projects-link">
							<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"/></svg>
							<span class="ml10">Measures</span>
							<i class="toggle fa fa-angle-down"></i>
						</a>
						<ul id="sidebar-actions" class="collapse">
							<li (click)="global.filterTrial('measures', {type: 'allmeasures'})" [ngClass]="global.getURLFromParam('type') == 'measures' ? 'active': ''" class="option pl45">
								<a class="pl50">
									All Measures
								</a>
							</li>
							<li (click)="global.filterTrial('measures', {type: 'management'})" [ngClass]="global.getURLFromParam('type') == 'management' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Management
								</a>
							</li>
							<li (click)="global.filterTrial('measures', {type: 'programs'})" [ngClass]="global.getURLFromParam('type') == 'programs' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Programs
								</a>
							</li>
							<li (click)="global.filterTrial('measures', {type: 'technology'})" [ngClass]="global.getURLFromParam('type') == 'technology' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Technology & Structure Design
								</a>
							</li>
							<li (click)="global.filterTrial('measures', {type: 'innovation'})" [ngClass]="global.getURLFromParam('type') == 'innovation' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Innovation
								</a>
							</li>
						</ul>
					</li>
				</ul>
				<ul class="sidebar-nav">
					<li>
						<a class="option sidebar-projects-link collapsed" data-target="#sidebar-projects" data-toggle="collapse" data-parent="#sidebar" id="sidebar-projects-link">
							<svg version="1.1" id="Layer_1" width="24" height="24" viewBox="0 0 28 28">
							<g id="_x36_68-gear4.png">
								<g>
								<path d="M14,9c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S16.762,9,14,9z M14,17c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3
								S15.657,17,14,17z M25.832,10.181l-1.18-0.295c-0.065-0.172-0.137-0.343-0.211-0.512l0.627-1.046c0.676-1.124,0.5-2.56-0.43-3.489
								L23.16,3.359c-0.54-0.54-1.258-0.837-2.02-0.837c-0.52,0-1.029,0.142-1.475,0.41l-1.041,0.624
								c-0.169-0.074-0.341-0.146-0.514-0.212l-0.295-1.18C17.497,0.89,16.357,0,15.045,0h-2.09c-1.314,0-2.454,0.891-2.772,2.165
								l-0.295,1.18C9.715,3.412,9.543,3.482,9.373,3.558L8.33,2.932C7.883,2.665,7.375,2.525,6.86,2.525
								c-0.763,0-1.479,0.296-2.019,0.833L3.361,4.84c-0.928,0.929-1.105,2.363-0.43,3.49l0.627,1.043
								c-0.075,0.17-0.146,0.342-0.213,0.515l-1.181,0.295c-1.275,0.319-2.164,1.459-2.164,2.771L0,15.044
								c0,1.313,0.889,2.453,2.161,2.772l1.185,0.297c0.066,0.173,0.137,0.344,0.212,0.513l-0.626,1.045
								c-0.677,1.125-0.5,2.562,0.433,3.491l1.474,1.476c0.541,0.541,1.259,0.838,2.021,0.838c0.518,0,1.026-0.141,1.471-0.407
								l1.043-0.626c0.17,0.075,0.341,0.146,0.514,0.212l0.295,1.181C10.5,27.109,11.639,28,12.953,28h2.092
								c1.314,0,2.455-0.891,2.772-2.165l0.295-1.181c0.173-0.066,0.345-0.137,0.515-0.212l1.043,0.626
								c0.443,0.267,0.952,0.407,1.47,0.407c0.762,0,1.479-0.296,2.018-0.833l1.482-1.484c0.929-0.926,1.106-2.362,0.428-3.491
								l-0.626-1.042c0.074-0.169,0.146-0.34,0.212-0.513l1.182-0.295C27.11,17.497,28,16.357,28,15.045v-2.091
								C28,11.639,27.109,10.498,25.832,10.181z M26,15.045c0,0.394-0.269,0.736-0.649,0.832l-1.776,0.443
								c-0.288,0.072-0.519,0.287-0.609,0.57c-0.156,0.487-0.352,0.956-0.582,1.406c-0.134,0.264-0.123,0.579,0.029,0.833l0.941,1.568
								c0.203,0.337,0.149,0.77-0.129,1.047l-1.479,1.48c-0.166,0.165-0.385,0.25-0.606,0.25c-0.151,0-0.304-0.04-0.44-0.122
								l-1.568-0.941c-0.137-0.081-0.289-0.122-0.442-0.122c-0.134,0-0.268,0.031-0.391,0.094c-0.45,0.23-0.919,0.426-1.407,0.582
								c-0.281,0.09-0.498,0.321-0.57,0.608l-0.443,1.776C15.782,25.732,15.439,26,15.045,26h-2.092c-0.393,0-0.736-0.268-0.831-0.649
								l-0.444-1.776c-0.07-0.288-0.287-0.519-0.568-0.608c-0.488-0.156-0.958-0.352-1.408-0.582c-0.122-0.062-0.256-0.094-0.391-0.094
								c-0.152,0-0.307,0.041-0.441,0.122l-1.568,0.941c-0.137,0.082-0.289,0.122-0.441,0.122c-0.221,0-0.44-0.086-0.605-0.251
								l-1.479-1.479c-0.277-0.277-0.33-0.71-0.129-1.046l0.941-1.569c0.152-0.254,0.164-0.568,0.027-0.832
								c-0.23-0.45-0.424-0.92-0.581-1.407c-0.091-0.282-0.321-0.497-0.608-0.569l-1.777-0.444C2.269,15.781,2,15.438,2,15.045
								l0.001-2.091c0-0.393,0.268-0.735,0.649-0.831l1.775-0.443c0.287-0.072,0.518-0.287,0.608-0.569
								c0.157-0.488,0.352-0.959,0.582-1.408c0.135-0.264,0.124-0.58-0.028-0.833L4.646,7.301C4.445,6.964,4.498,6.532,4.775,6.254
								l1.479-1.479c0.165-0.165,0.385-0.25,0.606-0.25c0.151,0,0.304,0.039,0.44,0.121l1.568,0.941C9.004,5.669,9.158,5.71,9.311,5.71
								c0.135,0,0.268-0.031,0.391-0.094c0.45-0.23,0.92-0.426,1.408-0.582c0.281-0.09,0.498-0.321,0.57-0.608l0.443-1.776
								C12.219,2.268,12.561,2,12.955,2h2.09c0.394,0,0.736,0.268,0.832,0.649l0.443,1.776c0.072,0.288,0.287,0.519,0.57,0.608
								c0.486,0.156,0.957,0.351,1.406,0.581c0.122,0.062,0.256,0.094,0.391,0.094c0.152,0,0.306-0.041,0.441-0.122l1.57-0.941
								c0.137-0.083,0.289-0.123,0.441-0.123c0.221,0,0.439,0.086,0.605,0.251l1.479,1.479c0.278,0.278,0.332,0.71,0.129,1.047
								l-0.941,1.57c-0.153,0.254-0.164,0.568-0.029,0.832c0.23,0.449,0.426,0.92,0.582,1.406c0.091,0.281,0.321,0.498,0.609,0.569
								l1.776,0.444C25.731,12.217,26,12.561,26,12.954V15.045z"/>
								</g>
							</g>
							</svg>
							<span class="ml10">Manage</span>
							<i class="toggle fa fa-angle-down"></i>
						</a>
						<ul id="sidebar-projects" class="collapse">
							<li (click)="global.filterTrial('project')" [ngClass]="appData.get('app_name') == 'project' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Project
								</a>
							</li>
							<li (click)="global.filterTrial('team')" [ngClass]="appData.get('app_name') == 'team' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Team
								</a>
							</li>
							<li (click)="global.filterTrial('billing')" [ngClass]="appData.get('app_name') == 'billing' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Billing
								</a>
							</li>
							<li (click)="global.filterTrial('agreement')" [ngClass]="appData.get('app_name') == 'agreement' ? 'active': ''" class="option pl45">
								<a class="pl50">
									Agreements
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
        </div>
    </div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm grey" *ngIf="sidebar_type == 'portfolios'">
    <div class="js-sidebar-content">
        <div class="project_list_options">
            <div class="option active">
                <svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-3h3.56c.69 1.19 1.97 2 3.45 2s2.75-.81 3.45-2H19v3zm0-5h-4.99c0 1.1-.9 2-2 2s-2-.9-2-2H5V5h14v9z"/></svg>
                <span class="ml10">All</span>
                <span class="floatr">{{config.portfolios_count}}</span>
            </div>
        </div>
    </div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm grey" *ngIf="sidebar_type == 'portfolio'">
    <div class="js-sidebar-content">
        <div class="project_list_options">
            <div class="option" [ngClass]="appData.get('app_name') == 'portfolio-overview' ? 'active': ''" (click)="changeAppPortfolio('portfolio-overview')">
                <svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"/></svg>
                <span class="ml10" translate>Overview</span>
            </div>
            <div class="option" [ngClass]="appData.get('app_name') == 'portfolio-performance' ? 'active': ''" (click)="changeAppPortfolio('portfolio-performance')">
                <svg width="30" height="30" viewBox="0 0 30 30"> <g fill="none" fill-rule="evenodd"> <g fill="#000" transform="translate(3 7)"> <path d="M12.4400831,5.94889812 C13.8220903,5.94985628 14.9406176,7.1667199 14.9406176,8.4976046 C14.9406176,9.83902906 13.8200713,10.9313318 12.4370546,10.9313318 C11.932304,10.9313318 11.1247031,10.6343021 10.7209026,10.3650591 C10.5593824,10.3650591 9.24703088,10.5193229 9.24703088,10.5193229 C9.24703088,10.5193229 9.32779097,11.3337592 9.40855107,11.8511658 C10.236342,12.5889492 11.3064133,13 12.4370546,13 C14.9507126,13 17,10.9888215 17,8.4976046 C17,6.0073459 14.9527316,4.0047908 12.4400831,4.00383264 L0,4 L0,5.95560524 L12.4400831,5.94889812 Z"></path> <path d="M18.9515991,8.5 C18.9515991,12.0822277 16.0432427,14.9818436 12.460252,14.9818436 C10.8389211,14.9818436 9.31396648,14.3872789 8.13034472,13.3297253 C6.97684056,12.2978934 6.22992403,10.8980447 6.02612825,9.36266294 C5.80626976,9.18261173 4.88366721,8.5 4.88366721,8.5 C4.88366721,8.5 4.22208389,9.25878724 3.97813131,9.50215317 C4.22208389,11.5608706 5.20994124,13.4444832 6.7539703,14.8245461 C8.31004641,16.2164804 10.324914,17 12.460252,17 C17.1796802,17 21.0006,13.1922137 21.0006,8.5 C21.0006,3.80877561 17.1796802,0 12.460252,0 L0.0006,0 L0.0006,2.01914572 L12.460252,2.01914572 C16.0432427,2.01914572 18.9515991,4.91777235 18.9515991,8.5"></path> </g> <path fill="#000" d="M15.5,2.9999 L3,2.9999 L3,5.0409 L15.5,5.0409 C21.276,5.0409 25.959,9.7239 25.959,15.4999 C25.959,21.2759 21.276,25.9589 15.5,25.9589 C12.886,25.9589 10.424,24.9979 8.518,23.2879 C7.829,22.6699 7.225,21.9629 6.721,21.1879 C6.262,20.4799 5.613,18.9229 5.613,18.9229 L4.254,18.3109 L3.712,19.6339 C3.712,19.6339 4.428,21.4029 5.009,22.2999 C5.611,23.2259 6.333,24.0689 7.155,24.8069 C9.432,26.8499 12.378,27.9999 15.5,27.9999 C22.403,27.9999 28,22.4039 28,15.4999 C28,8.5969 22.403,2.9999 15.5,2.9999"></path> </g> </svg>
                <span class="ml10" translate>Performance</span>
            </div>
            <div class="option" [ngClass]="appData.get('app_name') == 'portfolio-reports' ? 'active': ''" (click)="changeAppPortfolio('portfolio-reports')">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5 9.2h3V19H5V9.2zM10.6 5h2.8v14h-2.8V5zm5.6 8H19v6h-2.8v-6z"/></svg>
                <span class="ml10" translate>Reports</span>
            </div>
            <ul class="sidebar-nav mb0 pb0">
                <li>
                    <a class="option sidebar-projects-link collapsed" data-target="#sidebar-projects" data-toggle="collapse" data-parent="#sidebar" id="sidebar-projects-link">
                        <svg version="1.1" id="Layer_1" width="24" height="24" viewBox="0 0 28 28">
                        <g id="_x36_68-gear4.png">
                            <g>
                            <path d="M14,9c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S16.762,9,14,9z M14,17c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3
                            S15.657,17,14,17z M25.832,10.181l-1.18-0.295c-0.065-0.172-0.137-0.343-0.211-0.512l0.627-1.046c0.676-1.124,0.5-2.56-0.43-3.489
                            L23.16,3.359c-0.54-0.54-1.258-0.837-2.02-0.837c-0.52,0-1.029,0.142-1.475,0.41l-1.041,0.624
                            c-0.169-0.074-0.341-0.146-0.514-0.212l-0.295-1.18C17.497,0.89,16.357,0,15.045,0h-2.09c-1.314,0-2.454,0.891-2.772,2.165
                            l-0.295,1.18C9.715,3.412,9.543,3.482,9.373,3.558L8.33,2.932C7.883,2.665,7.375,2.525,6.86,2.525
                            c-0.763,0-1.479,0.296-2.019,0.833L3.361,4.84c-0.928,0.929-1.105,2.363-0.43,3.49l0.627,1.043
                            c-0.075,0.17-0.146,0.342-0.213,0.515l-1.181,0.295c-1.275,0.319-2.164,1.459-2.164,2.771L0,15.044
                            c0,1.313,0.889,2.453,2.161,2.772l1.185,0.297c0.066,0.173,0.137,0.344,0.212,0.513l-0.626,1.045
                            c-0.677,1.125-0.5,2.562,0.433,3.491l1.474,1.476c0.541,0.541,1.259,0.838,2.021,0.838c0.518,0,1.026-0.141,1.471-0.407
                            l1.043-0.626c0.17,0.075,0.341,0.146,0.514,0.212l0.295,1.181C10.5,27.109,11.639,28,12.953,28h2.092
                            c1.314,0,2.455-0.891,2.772-2.165l0.295-1.181c0.173-0.066,0.345-0.137,0.515-0.212l1.043,0.626
                            c0.443,0.267,0.952,0.407,1.47,0.407c0.762,0,1.479-0.296,2.018-0.833l1.482-1.484c0.929-0.926,1.106-2.362,0.428-3.491
                            l-0.626-1.042c0.074-0.169,0.146-0.34,0.212-0.513l1.182-0.295C27.11,17.497,28,16.357,28,15.045v-2.091
                            C28,11.639,27.109,10.498,25.832,10.181z M26,15.045c0,0.394-0.269,0.736-0.649,0.832l-1.776,0.443
                            c-0.288,0.072-0.519,0.287-0.609,0.57c-0.156,0.487-0.352,0.956-0.582,1.406c-0.134,0.264-0.123,0.579,0.029,0.833l0.941,1.568
                            c0.203,0.337,0.149,0.77-0.129,1.047l-1.479,1.48c-0.166,0.165-0.385,0.25-0.606,0.25c-0.151,0-0.304-0.04-0.44-0.122
                            l-1.568-0.941c-0.137-0.081-0.289-0.122-0.442-0.122c-0.134,0-0.268,0.031-0.391,0.094c-0.45,0.23-0.919,0.426-1.407,0.582
                            c-0.281,0.09-0.498,0.321-0.57,0.608l-0.443,1.776C15.782,25.732,15.439,26,15.045,26h-2.092c-0.393,0-0.736-0.268-0.831-0.649
                            l-0.444-1.776c-0.07-0.288-0.287-0.519-0.568-0.608c-0.488-0.156-0.958-0.352-1.408-0.582c-0.122-0.062-0.256-0.094-0.391-0.094
                            c-0.152,0-0.307,0.041-0.441,0.122l-1.568,0.941c-0.137,0.082-0.289,0.122-0.441,0.122c-0.221,0-0.44-0.086-0.605-0.251
                            l-1.479-1.479c-0.277-0.277-0.33-0.71-0.129-1.046l0.941-1.569c0.152-0.254,0.164-0.568,0.027-0.832
                            c-0.23-0.45-0.424-0.92-0.581-1.407c-0.091-0.282-0.321-0.497-0.608-0.569l-1.777-0.444C2.269,15.781,2,15.438,2,15.045
                            l0.001-2.091c0-0.393,0.268-0.735,0.649-0.831l1.775-0.443c0.287-0.072,0.518-0.287,0.608-0.569
                            c0.157-0.488,0.352-0.959,0.582-1.408c0.135-0.264,0.124-0.58-0.028-0.833L4.646,7.301C4.445,6.964,4.498,6.532,4.775,6.254
                            l1.479-1.479c0.165-0.165,0.385-0.25,0.606-0.25c0.151,0,0.304,0.039,0.44,0.121l1.568,0.941C9.004,5.669,9.158,5.71,9.311,5.71
                            c0.135,0,0.268-0.031,0.391-0.094c0.45-0.23,0.92-0.426,1.408-0.582c0.281-0.09,0.498-0.321,0.57-0.608l0.443-1.776
                            C12.219,2.268,12.561,2,12.955,2h2.09c0.394,0,0.736,0.268,0.832,0.649l0.443,1.776c0.072,0.288,0.287,0.519,0.57,0.608
                            c0.486,0.156,0.957,0.351,1.406,0.581c0.122,0.062,0.256,0.094,0.391,0.094c0.152,0,0.306-0.041,0.441-0.122l1.57-0.941
                            c0.137-0.083,0.289-0.123,0.441-0.123c0.221,0,0.439,0.086,0.605,0.251l1.479,1.479c0.278,0.278,0.332,0.71,0.129,1.047
                            l-0.941,1.57c-0.153,0.254-0.164,0.568-0.029,0.832c0.23,0.449,0.426,0.92,0.582,1.406c0.091,0.281,0.321,0.498,0.609,0.569
                            l1.776,0.444C25.731,12.217,26,12.561,26,12.954V15.045z"/>
                            </g>
                        </g>
                        </svg>
                        <span class="ml10" translate>Manage</span>
                        <i class="toggle fa fa-angle-down"></i>
                    </a>
                    <ul id="sidebar-projects" class="collapse">
                        <li [ngClass]="appData.get('app_name') == 'portfolio' ? 'active': ''" (click)="changeAppPortfolio('portfolio')" class="option pl45">
                            <a class="pl50" translate>
                                Portfolio
                            </a>
                        </li>
                        <li [ngClass]="appData.get('app_name') == 'portfolio-team' ? 'active': ''" (click)="changeAppPortfolio('portfolio-team')" class="option pl45">
                            <a class="pl50" translate>
                                Team
                            </a>
                        </li>
                        <li [ngClass]="appData.get('app_name') == 'activity' ? 'active': ''" (click)="changeAppPortfolio('activity')" class="option pl45">
                            <a class="pl50" translate>
                                Activity
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

			<div class="option">
				<svg enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/><path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/><path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/></g></g><g display="none"><g display="inline"/><g display="inline"><path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/><path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/><path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/></g></g></svg>
				<span class="ml10" translate>Portfolio Tools</span>
			</div>
			<div (click)="changeAppPortfolio('portfolio-efficiency-analysis')" class="pl56 option" [ngClass]="appData.get('app_name') == 'portfolio-efficiency-analysis' ? 'active': ''">
				<span class="" translate>Efficiency Analysis</span>
			</div>
        </div>
    </div>
</nav>

<nav *ngIf="sidebar_type == 'admin_section'" class="sidebar hidden-sm grey" id="sidebar" role="navigation" style="top: 64px;">
	<div class="js-sidebar-content">
		<div class="project_list_options navbar_info sidebar-tag" style="height: calc(100vh - 60px); font-size: 14px;">
			<div (click)="changeAdminApp('admin_team')" class="option" [ngClass]="appData.get('app_name') == 'admin_team' ? 'active': ''">
				<span class="pr3 fa fa-users"></span> 
				<span class="ml10">Team</span>
			</div>
			<!-- <div (click)="changeAdminApp('team_export')" class="option" [ngClass]="appData.get('app_name') =='team_export' ? 'active': ''">
				<span class="pr3 fa fa-users"></span>
				<span class="ml10">Team Member Lookup</span>
			</div> -->
			<!-- <div (click)="changeAdminApp('analysis')" class="option" [ngClass]="appData.get('app_name') =='analysis' ? 'active': ''">
				<span class="pr3 icon-bar-chart"></span>
				<span class="ml10">Analysis</span>
			</div> -->
			<div (click)="changeAdminApp('subscription_discount')" class="option" [ngClass]="appData.get('app_name') =='subscription_discount'  ? 'active': ''">
				<span class="pr3 icon-money"></span>
				<span class="ml10">Arc Subscriptions</span>
			</div>
			<div *ngIf="config.config.settings.ENV_FLAG != 'PRD'" (click)="changeAdminApp('cancel-subscription')" class="option" [ngClass]="appData.get('app_name') =='cancel-subscription'  ? 'active': ''">
				<span class="pr3 icon-trash"></span>
				<span class="ml10">Cancel Subscription</span>
			</div>
			<!-- <div (click)="changeAdminApp('admin_export')" class="option" [ngClass]="appData.get('app_name') =='admin_export' ? 'active': ''">
				<span class="pr3 fa fa-download"></span>
				<span class="ml10">Export Data</span>
			</div> -->
			<div (click)="changeAdminApp('admin_certification')" class="option" [ngClass]="appData.get('app_name') =='admin_certification' ? 'active': ''">
				<span class="pr3 icon-file"></span>
				<span class="ml10">Certification / Base Score</span>
			</div>
			<!-- <div (click)="changeAdminApp('review')" class="option" [ngClass]="appData.get('app_name') =='review' ? 'active': ''">
				<span class="pr3 icon-file"></span>
				<span class="ml10">Review</span> 
			</div> -->
			<div *ngIf="config.config.settings.ENV_FLAG != 'PRD'" (click)="changeAdminApp('test_project')" class="option" [ngClass]="appData.get('app_name') =='test_project' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Test Projects</span>
			</div>
			<div (click)="changeAdminApp('recompute_score')" class="option" [ngClass]="appData.get('app_name') =='recompute_score' ? 'active': ''">
				<span class="pr3 fa fa-circle"></span>
				<span class="ml10">Scores</span>
			</div>
			<div *ngIf="config.config.settings.ENV_FLAG != 'PRD'" (click)="changeAdminApp('sync_project')" class="option" [ngClass]="appData.get('app_name') == 'sync_project' ? 'active': ''">
				<span class="pr3 fa fa-database"></span>
				<span class="ml10">Sync Project</span>
			</div>
			<div (click)="changeAdminApp('admin_score_version')" class="option" [ngClass]="appData.get('app_name') == 'admin_score_version' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Score Version Extension</span>
			</div>
			<div (click)="changeAdminApp('return_review')" class="option" [ngClass]="appData.get('app_name') == 'return_review' ? 'active': ''">
				<span class="pr3 fa fa-circle"></span>
				<span class="ml10">Review</span>
			</div>
			<div (click)="changeAdminApp('user_data_masking')" class="option" [ngClass]="appData.get('app_name') == 'user_data_masking' ? 'active': ''">
				<span class="pr3 fa fa-database"></span>
				<span class="ml10">GDPR Support</span>
			</div>
			<!-- <div (click)="changeAdminApp('recompute_improvement_scores')" class="option" [ngClass]="appData.get('app_name') == 'recompute_improvement_scores' ? 'active': ''">
				<span class="pr3 fa fa-circle"></span>
				<span class="ml10">Recompute Improvement Scores</span>
			</div> -->
			<!-- <div (click)="changeAdminApp('downgrade_score_version')" class="option" [ngClass]="appData.get('app_name') == 'downgrade_score_version' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Downgrade Score Version</span>
			</div> -->
			<!-- <div (click)="changeAdminApp('recompute_reentry_score')" class="option" [ngClass]="appData.get('app_name') == 'recompute_reentry_score' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Recompute Re-entry Scores</span>
			</div> -->
			<!-- <div (click)="changeAdminApp('offline_subscription')" class="option" [ngClass]="appData.get('app_name') == 'offline_subscription' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Offline Subscription</span>
			</div> -->
			<div (click)="changeAdminApp('partial_maintenance')" class="option" [ngClass]="appData.get('app_name') == 'partial_maintenance' ? 'active': ''">
				<span class="pr3 fa fa-database"></span>
				<span class="ml10">Partial Maintenance</span>
			</div>
			<div (click)="changeAdminApp('data_transfer')" class="option" [ngClass]="appData.get('app_name') == 'data_transfer' ? 'active': ''">
				<span class="pr3 fa fa-database"></span>
				<span class="ml10">Data Transfer</span>
			</div>
			<div (click)="changeAdminApp('transit_survey')" class="option" [ngClass]="appData.get('app_name') == 'transit_survey' ? 'active': ''">
				<span class="pr3 fa fa-database"></span>
				<span class="ml10">Add telecommute data</span>
			</div>
			<div (click)="changeAdminApp('admin_deactivate')" class="option" [ngClass]="appData.get('app_name') == 'admin_deactivate' ? 'active': ''">
				<span class="pr3 fa fa-circle"></span>
				<span class="ml10">Deactivate Project</span>
			</div>
			<div *ngIf="config.config.settings.ENV_FLAG != 'PRD'"(click)="changeAdminApp('recompute_analysisinfo')" class="option" [ngClass]="appData.get('app_name') == 'recompute_analysisinfo' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Recompute AnalysisInfo</span>
			</div>
			<div (click)="changeAdminApp('covid_flag')" class="option" [ngClass]="appData.get('app_name') == 'covid_flag' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Impacted by COVID-19</span>
			</div>
			<div (click)="changeAdminApp('admin_pre_payment')" class="option" [ngClass]="appData.get('app_name') == 'admin_pre_payment' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Pre-payment Blockers</span>
			</div>
			<div (click)="changeAdminApp('export_racetrack')" class="option" [ngClass]="appData.get('app_name') == 'export_racetrack' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Export Racetrack</span>
			</div>
			<div (click)="changeAdminApp('owner_info')" class="option" [ngClass]="appData.get('app_name') == 'owner_info' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Update Owner</span>
			</div>
			<div (click)="changeAdminApp('order_update')" class="option" [ngClass]="appData.get('app_name') == 'order_update' ? 'active': ''">
				<span class="pr3 fa icon-file"></span>
				<span class="ml10">Review Orders</span>
			</div>
			<div (click)="changeAdminApp('surveys_update')" class="option" [ngClass]="appData.get('app_name') == 'surveys_update' ? 'active': ''">
				<span class="pr3 fa fa-list"></span>
				<span class="ml10">Surveys Update</span>
			</div>
		</div>
	</div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm white"  *ngIf="sidebar_type == 'notifications'">
    <div class="js-sidebar-content">
        <div class="project_list_options">
            <div [ngClass]="!config.starred ? 'active': ''" (click)="config.starred = false;" class="option">
                <span class="ml10">All</span>
            </div>
            <div class="divider mt8 mb8"></div>
            <div [ngClass]="config.starred ? 'active': ''" (click)="config.starred = true;" class="option">
                <i class="far fa-star"></i>
                <span class="ml10">Starred</span>
            </div>
        </div>
    </div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm grey"  *ngIf="sidebar_type == 'user-nav'">
    <div class="js-sidebar-content">
        <div class="project_list_options">
            <div class="option" [ngClass]="appData.get('app_name') == 'user-profile' ? 'active': ''"(click)="global.filterAccess('user-profile')">
				<svg width="30" height="30" viewBox="0 0 30 30"><g id="User_2">
					<path d="M14.5,1C6.505,1,0,7.505,0,15.5S6.505,30,14.5,30S29,23.495,29,15.5S22.495,1,14.5,1z M14.5,5c2.206,0,4,1.794,4,4
					s-1.794,4-4,4c-2.206,0-4-1.794-4-4S12.294,5,14.5,5z M22,24H7c-0.276,0-0.5-0.224-0.5-0.5c0-4.687,3.589-8.5,8-8.5s8,3.813,8,8.5
					C22.5,23.]776,22.276,24,22,24z"/>
				</g>
				</svg>
				<span class="ml10" translate>Profile</span>
			</div>        
			<div class="option" [ngClass]="appData.get('app_name') == 'user-reports' ? 'active': ''" (click)="global.filterAccess('user-reports')">
				<i class="pr7 icon-file-alt"></i>
				<span class="ml10" translate>Reports</span>
			</div>        
			<div class="option" [ngClass]="appData.get('app_name') == 'user-billing' ? 'active': ''" (click)="global.filterAccess('user-billing')">
				<i class="pr3 fa fa-credit-card"></i>
				<span class="ml10" translate>Billing</span>
			</div> 
        </div>
    </div>
</nav>

<nav id="sidebar" role="navigation" class="sidebar hidden-sm grey" *ngIf="sidebar_type == 'insight'" style="top: 178px;">
    <div class="js-sidebar-content">
        <div class="project_list_options">
            <div class="option" [ngClass]="appData.get('app_name') == 'reduction' ? 'active': ''" (click)="changeModel('reduction')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 1H5c-1.1 0-1.99.9-1.99 2L3 15.93c0 .69.35 1.3.88 1.66L12 23l8.11-5.41c.53-.36.88-.97.88-1.66L21 3c0-1.1-.9-2-2-2zm-7 19.6l-7-4.66V3h14v12.93l-7 4.67zm-2.01-7.42l-2.58-2.59L6 12l4 4 8-8-1.42-1.42z"/></svg>
				<span class="ml10">Reductions</span>
			</div>
			<div class="option" [ngClass]="appData.get('app_name') == 'strategies' ? 'active': ''" (click)="changeModel('strategies')">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"/>
					<path fill="none" d="M0 0h24v24H0V0z"/>
				</svg>        
				<span class="ml10">Strategies</span>
			</div>
			<div class="option" [ngClass]="appData.get('app_name') == 'comparison' ? 'active': ''" (click)="changeModel('comparison')">
				<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 1H5c-1.1 0-1.99.9-1.99 2L3 15.93c0 .69.35 1.3.88 1.66L12 23l8.11-5.41c.53-.36.88-.97.88-1.66L21 3c0-1.1-.9-2-2-2zm-7 19.6l-7-4.66V3h14v12.93l-7 4.67zm-2.01-7.42l-2.58-2.59L6 12l4 4 8-8-1.42-1.42z"/></svg>
				<span class="ml10">Comparisons</span>
			</div>
			<div class="option" [ngClass]="appData.get('app_name') == 'zepi-score' ? 'active': ''" (click)="changeModel('zepi-score')">
				<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg>
				<span class="ml10">How scoring works</span>
			</div>
		
			<ul class="sidebar-nav">
				<li>
					<a class="option sidebar-label-link collapsed" data-target="#sidebar-label" data-toggle="collapse" data-parent="#sidebar" id="sidebar-label-link">
						<svg version="1.1" id="Layer_1" width="24" height="24" viewBox="0 0 28 28">
							<g id="_x36_68-gear4.png">
								<g>
								<path d="M14,9c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S16.762,9,14,9z M14,17c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3
								S15.657,17,14,17z M25.832,10.181l-1.18-0.295c-0.065-0.172-0.137-0.343-0.211-0.512l0.627-1.046c0.676-1.124,0.5-2.56-0.43-3.489
								L23.16,3.359c-0.54-0.54-1.258-0.837-2.02-0.837c-0.52,0-1.029,0.142-1.475,0.41l-1.041,0.624
								c-0.169-0.074-0.341-0.146-0.514-0.212l-0.295-1.18C17.497,0.89,16.357,0,15.045,0h-2.09c-1.314,0-2.454,0.891-2.772,2.165
								l-0.295,1.18C9.715,3.412,9.543,3.482,9.373,3.558L8.33,2.932C7.883,2.665,7.375,2.525,6.86,2.525
								c-0.763,0-1.479,0.296-2.019,0.833L3.361,4.84c-0.928,0.929-1.105,2.363-0.43,3.49l0.627,1.043
								c-0.075,0.17-0.146,0.342-0.213,0.515l-1.181,0.295c-1.275,0.319-2.164,1.459-2.164,2.771L0,15.044
								c0,1.313,0.889,2.453,2.161,2.772l1.185,0.297c0.066,0.173,0.137,0.344,0.212,0.513l-0.626,1.045
								c-0.677,1.125-0.5,2.562,0.433,3.491l1.474,1.476c0.541,0.541,1.259,0.838,2.021,0.838c0.518,0,1.026-0.141,1.471-0.407
								l1.043-0.626c0.17,0.075,0.341,0.146,0.514,0.212l0.295,1.181C10.5,27.109,11.639,28,12.953,28h2.092
								c1.314,0,2.455-0.891,2.772-2.165l0.295-1.181c0.173-0.066,0.345-0.137,0.515-0.212l1.043,0.626
								c0.443,0.267,0.952,0.407,1.47,0.407c0.762,0,1.479-0.296,2.018-0.833l1.482-1.484c0.929-0.926,1.106-2.362,0.428-3.491
								l-0.626-1.042c0.074-0.169,0.146-0.34,0.212-0.513l1.182-0.295C27.11,17.497,28,16.357,28,15.045v-2.091
								C28,11.639,27.109,10.498,25.832,10.181z M26,15.045c0,0.394-0.269,0.736-0.649,0.832l-1.776,0.443
								c-0.288,0.072-0.519,0.287-0.609,0.57c-0.156,0.487-0.352,0.956-0.582,1.406c-0.134,0.264-0.123,0.579,0.029,0.833l0.941,1.568
								c0.203,0.337,0.149,0.77-0.129,1.047l-1.479,1.48c-0.166,0.165-0.385,0.25-0.606,0.25c-0.151,0-0.304-0.04-0.44-0.122
								l-1.568-0.941c-0.137-0.081-0.289-0.122-0.442-0.122c-0.134,0-0.268,0.031-0.391,0.094c-0.45,0.23-0.919,0.426-1.407,0.582
								c-0.281,0.09-0.498,0.321-0.57,0.608l-0.443,1.776C15.782,25.732,15.439,26,15.045,26h-2.092c-0.393,0-0.736-0.268-0.831-0.649
								l-0.444-1.776c-0.07-0.288-0.287-0.519-0.568-0.608c-0.488-0.156-0.958-0.352-1.408-0.582c-0.122-0.062-0.256-0.094-0.391-0.094
								c-0.152,0-0.307,0.041-0.441,0.122l-1.568,0.941c-0.137,0.082-0.289,0.122-0.441,0.122c-0.221,0-0.44-0.086-0.605-0.251
								l-1.479-1.479c-0.277-0.277-0.33-0.71-0.129-1.046l0.941-1.569c0.152-0.254,0.164-0.568,0.027-0.832
								c-0.23-0.45-0.424-0.92-0.581-1.407c-0.091-0.282-0.321-0.497-0.608-0.569l-1.777-0.444C2.269,15.781,2,15.438,2,15.045
								l0.001-2.091c0-0.393,0.268-0.735,0.649-0.831l1.775-0.443c0.287-0.072,0.518-0.287,0.608-0.569
								c0.157-0.488,0.352-0.959,0.582-1.408c0.135-0.264,0.124-0.58-0.028-0.833L4.646,7.301C4.445,6.964,4.498,6.532,4.775,6.254
								l1.479-1.479c0.165-0.165,0.385-0.25,0.606-0.25c0.151,0,0.304,0.039,0.44,0.121l1.568,0.941C9.004,5.669,9.158,5.71,9.311,5.71
								c0.135,0,0.268-0.031,0.391-0.094c0.45-0.23,0.92-0.426,1.408-0.582c0.281-0.09,0.498-0.321,0.57-0.608l0.443-1.776
								C12.219,2.268,12.561,2,12.955,2h2.09c0.394,0,0.736,0.268,0.832,0.649l0.443,1.776c0.072,0.288,0.287,0.519,0.57,0.608
								c0.486,0.156,0.957,0.351,1.406,0.581c0.122,0.062,0.256,0.094,0.391,0.094c0.152,0,0.306-0.041,0.441-0.122l1.57-0.941
								c0.137-0.083,0.289-0.123,0.441-0.123c0.221,0,0.439,0.086,0.605,0.251l1.479,1.479c0.278,0.278,0.332,0.71,0.129,1.047
								l-0.941,1.57c-0.153,0.254-0.164,0.568-0.029,0.832c0.23,0.449,0.426,0.92,0.582,1.406c0.091,0.281,0.321,0.498,0.609,0.569
								l1.776,0.444C25.731,12.217,26,12.561,26,12.954V15.045z"/>
								</g>
							</g>
							</svg>
						<span class="ml10">Manage</span>
					</a>
					<ul id="sidebar-label">
						<li [ngClass]="appData.get('app_name') == 'manage' ? 'active': ''" (click)="changeModel('manage')" class="option pl45">
							<a class="pl50">
								Model
							</a>
						</li>
						<li [ngClass]="appData.get('app_name') == 'team' ? 'active': ''" (click)="changeModel('team')" class="option pl45">
							<a class="pl50">
								Team
							</a>
						</li>
					</ul>
				</li>
			</ul>
        </div>
    </div>
</nav>