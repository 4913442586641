<div class="">
    <div class="p30 width-100-perc">
        <h4>Add organization</h4>

        <form name="organizationForm">           
            <fieldset>
                <section class="widget pt20" style="margin-bottom: 0px !important;padding: 15px 0px;"> 
                    <table style="border: none;" class="width-100-perc">
                        <thead>
                            <tr>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="">
                                    <div [ngClass]="orgNameError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Organization Name:</div>
                                        <input (input)="validateOrgValues('orgName')" name="name" class="form-control" maxlength="100" type="text" [(ngModel)]="orgName">

                                        <span class="floatl width-100-perc error_message mb0 help-block required-field" *ngIf="orgNameError">This field is required.</span>
                                        
                                        <span class="floatl error_message help-block required-field" *ngIf='isFormSubmittedOwner'>{{formValidation.nameMsg}}</span>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                    <div [ngClass]="orgContactNameError ? 'has-error': ' ' " class="mt10 mb10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Owner's Representative(Employee or Officer of Owner):</div>
                                        <input name="orgContactName" (input)="validateOrgValues('orgContactName')" class="form-control" maxlength="100" type="text" [(ngModel)]="orgContactName">
                                        
                                        <span class="floatl error_message help-block required-field" *ngIf="orgContactNameError">This field is required.</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                    <div [ngClass]="orgContactEmailErrorLength || orgContactEmailError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Owner Contact Email:</div>
                                        <input (input)="validateOrgValues('orgContactEmail')" name="owner_email"  class="form-control" maxlength="254" type="text" [(ngModel)]="orgContactEmail">
                                        <span class="mb0 floatl width-100-perc error_message help-block required-field" *ngIf="orgContactEmailError">This field is required.</span>
                                        
                                        <span class="floatl error_message help-block required-field" *ngIf='isFormSubmittedOwner'>{{formValidation.emailMsg}}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                    <div [ngClass]="orgWebSiteError ? 'has-error': ' ' " class="mb10 mt10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Website:</div>
                                        <input name="orgWebSite" (input)="validateOrgValues('orgWebSite')" class="form-control" maxlength="40" type="text" [(ngModel)]="orgWebSite">
                                        
                                        <span class="floatl error_message help-block" *ngIf="orgWebSiteError">Please enter valid web url.</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                    <div class="mb10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Industry Category:</div>
                                        
                                        <select (change)="resetSubCat(this)" class="form-control" [(ngModel)]="orgCategory" name="orgCategory">
                                            <option value="2" selected="selected">Product Manufacturers</option>
                                            <option value="3">Utility / Energy Service Companies</option>
                                            <option value="4">Real Estate Providers</option>
                                            <option value="5">Professional Societies and Trade Associations</option>
                                            <option value="6">Nonprofit and Environmental Organizations</option>
                                            <option value="7">State and Local Governments</option>
                                            <option value="8">Federal Government</option>
                                            <option value="9">Professional Firms</option>
                                            <option value="10">Contractors and Builders</option>
                                            <option value="12">Educational Institutions</option>
                                            <option value="13">Corporate and Retail</option>
                                            <option value="501">Financial Institutions</option>
                                        </select>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                    <div class="mb10" style="height: 66px;">
                                        <div class="mb10 font-weight-600">Industry Sub-Category:</div>
                                        
                                        <select name="orgSubCategoryError" [ngClass]="orgSubCategoryError ? 'has-error': ' ' " class="form-control" [(ngModel)]="orgSubCategory">
                                            <option *ngIf="orgCategory == '2'" value="27">PM:Product Manufacturer</option>
                                            <option *ngIf="orgCategory == '2'" value="28">PM:Building Controls</option>
                                            <option *ngIf="orgCategory == '2'" value="61">PM:Distributors</option>
                                            
                                            <option *ngIf="orgCategory == '3'" value="30">Energy Service Companies</option>
                                            <option *ngIf="orgCategory == '3'" value="31">Utilities</option>

                                            <option *ngIf="orgCategory == '4'" value="108">RE:Comm-Brokage and Mgm</option>
                                            <option *ngIf="orgCategory == '4'" value="109">RE:Commerical REITs</option>
                                            <option *ngIf="orgCategory == '4'" value="110">RE: Residential Brokerage  and  Management</option>
                                            <option *ngIf="orgCategory == '4'" value="111">RE:Residential Property Investor</option>
                                            <option *ngIf="orgCategory == '4'" value="112">RE: Institutional Investor</option>
                                            <option *ngIf="orgCategory == '4'" value="113">RE:Private Held Owner</option>
                                            <option *ngIf="orgCategory == '4'" value="114">RE:Service Contractor</option>

                                            <option *ngIf="orgCategory == '5'" value="5">Prof Soc/Trade Assn</option>
                                            <option *ngIf="orgCategory == '5'" value="104">TradeGrp and Labor-union</option>
                                            <option *ngIf="orgCategory == '5'" value="105">Advocacy Organization</option>

                                            <option *ngIf="orgCategory == '6'" value="100">Charitable.org and Foundation</option>
                                            <option *ngIf="orgCategory == '6'" value="101">Socia and Membership org</option>
                                            <option *ngIf="orgCategory == '6'" value="102">Religious</option>
                                            <option *ngIf="orgCategory == '6'" value="103">Other Non-Profit</option>

                                            <option *ngIf="orgCategory == '7'" value="62">SLG: State Government</option>
                                            <option *ngIf="orgCategory == '7'" value="63">SLG: Local Government</option>

                                            <option *ngIf="orgCategory == '8'" value="8">Federal Government</option>

                                            <option *ngIf="orgCategory == '9'" value="32">PF:Accountants</option>
                                            <option *ngIf="orgCategory == '9'" value="33">PF:Architects</option>
                                            <option *ngIf="orgCategory == '9'" value="34">PF:Architects/Engineers</option>
                                            <option *ngIf="orgCategory == '9'" value="35">PF:Attorneys</option>
                                            <option *ngIf="orgCategory == '9'" value="36">PF:Building/LEED Consultant</option>
                                            <option *ngIf="orgCategory == '9'" value="56">PF:Engineers</option>
                                            <option *ngIf="orgCategory == '9'" value="57">PF:Interior Designer</option>
                                            <option *ngIf="orgCategory == '9'" value="58">PF:Landscape Architect</option>
                                            <option *ngIf="orgCategory == '9'" value="59">PF:Planners</option>
                                            <option *ngIf="orgCategory == '9'" value="60">PF:Commissioning Pr</option>
                                            <option *ngIf="orgCategory == '9'" value="74">PF:Residential Designer</option>
                                            <option *ngIf="orgCategory == '9'" value="107">PF:Other</option>

                                            <option *ngIf="orgCategory == '10'" value="75">CB:Commercial</option>
                                            <option *ngIf="orgCategory == '10'" value="76">CB:Residential</option>
                                            <option *ngIf="orgCategory == '10'" value="77">CB:Mixed</option>
                                            <option *ngIf="orgCategory == '10'" value="78">CB:Others</option>

                                            <option *ngIf="orgCategory == '12'" value="91">Indp/Nonprofit.Education</option>
                                            <option *ngIf="orgCategory == '12'" value="92">E and S-sch:Public</option>
                                            <option *ngIf="orgCategory == '12'" value="93">E and S-sch:Public Charter</option>
                                            <option *ngIf="orgCategory == '12'" value="94">E and S-sch:Indpendent</option>
                                            <option *ngIf="orgCategory == '12'" value="95">C and Uni:2yrs-Instiution</option>
                                            <option *ngIf="orgCategory == '12'" value="96">C and Uni:4yrs-Inst(public)</option>
                                            <option *ngIf="orgCategory == '12'" value="97">C and Uni:4yrs-Inst(private)</option>
                                            <option *ngIf="orgCategory == '12'" value="98">C and Uni:4yrs-grad(public)</option>
                                            <option *ngIf="orgCategory == '12'" value="99">C and Uni:4yrs-grad(private)</option>

                                            <option *ngIf="orgCategory == '13'" value="11">Insurance Companies</option>
                                            <option *ngIf="orgCategory == '13'" value="81">Health,Pharma and Biotec</option>
                                            <option *ngIf="orgCategory == '13'" value="83">Retail: Restaurants, Bars and Food Services</option>
                                            <option *ngIf="orgCategory == '13'" value="84">Retail: Grocery, Convenience Store</option>
                                            <option *ngIf="orgCategory == '13'" value="85">Retail: Other</option>
                                            <option *ngIf="orgCategory == '13'" value="86">Technology</option>
                                            <option *ngIf="orgCategory == '13'" value="87">Transportation, Delivery and Warehousing Services</option>
                                            <option *ngIf="orgCategory == '13'" value="89">Other Corporate</option>
                                            <option *ngIf="orgCategory == '13'" value="501">Financial Institution</option>

                                            <option *ngIf="orgCategory == '501'" value="501">Financial Institutions</option>
                                        </select>
                                        
                                        <span class="floatl error_message help-block required-field" *ngIf="orgSubCategoryError">This field is required.</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <div class="clearfix" [ngClass]="creating_org ? 'not-active' : ''">
                    <div class="btn-toolbar clear-left">
                        <button  class="floatl btn btn-primary mr10" type="submit" (click)="createOrganization()">
                            ADD
                        </button> 
                        <button (click)="closeModal()" data-dismiss="modal" type="button" class="floatl btn cancel-btn">
                            CANCEL
                        </button>
                        <div style="margin-top: 7px;" *ngIf="creating_org" class="ml10 mr10 dataInputLoaderSpinner floatl"></div>
                    </div>
                    <div class="error_message " *ngIf="error_org">{{error_msg_org}}</div>
                </div>
            </fieldset>
        </form> 
    </div>
</div>