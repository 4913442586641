import { Injectable } from '@angular/core';

import { AppData } from './appdata.service';
import { API } from './api.service';

@Injectable({providedIn: 'root'})
export class ArcAuthService
{
	constructor(
		private api: API,
		private appData: AppData
	){}

	getAuth()
	{
		return this.api.get('/auth/access/');
	}

	getUSGBCUserProfile()
	{
		return this.api.get('/auth/usgbc/userprofile/');
	}

	getLOToken()
	{
		return this.api.get('/auth/leed-online/token/?mode=key');
	}

	getLeedOnlineToken()
	{                
		let auth_access_path = '/auth/leed-online/token/';
		return this.api.get(auth_access_path);
	}

	getAuthDetail()
	{                
		let auth_access_path = '/auth/detail/';
		return this.api.get(auth_access_path);
	}

	getUserPreference()
	{                
		let auth_access_path = '/auth/user/preference/';
		return this.api.get(auth_access_path);
	}

	updateUserPreference(ptype: any, is_selected: any)
	{                
		let payload = {"ptype": ptype, "is_selected": is_selected};
		let url = '/auth/user/preference/';
		return this.api.post(url, payload);
	}

	getProjectTeam(leed_id: number)
	{
		return this.api.get(
			'/assets/LEED:' + leed_id + '/teams/'
		);
	}
}