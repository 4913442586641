<app-sidebar [sidebar_type]="'portfolios'"></app-sidebar>

<div class="content-wrap">
	<div id="content" class="content">
		
        <nav class="page-controls navbar_info navbar-default">
            <div class="align_lr">
                <div class="flex">
                    <h3 translate>Portfolios</h3>
                    <button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.filterAccess('upgrade_arc', undefined, {'mode': 'purchase_arc_pro'})" class="mt15 ml15 arc_pro_button btn btn-default">
                        <div class="arc_logo_racetrack"></div>
                        <a class="fw-500 fs14" translate>Upgrade my account</a>
                    </button>
                    <div *ngIf="appData.get('user_role') == 'arc_pro'" class="mt22 ml15">
                        <div class="arc_logo_racetrack"></div>
                        <span class="fw-500 fs15">{{config.arc_pro_label}}</span>
                    </div>
                    <div *ngIf="appData.get('is_gsa_user')" class="mt22 ml15">
                        <div class="arc_gsa_logo"></div>
                        <span class="fw-500 fs15">{{config.arc_gsa_label}}</span>
                    </div>
                </div>
                <div class="nav_buttons">
                    <input #elem (input)="nameChanged(elem)" class="hide uploadFile" type="file">
                    <button (click)="openModal('gresb_modal')" class="blue outline mr25 mt15" translate>
                        <img class="plus_icon" src="../../assets/images/plus.svg">
                        Import from GRESB
                    </button>
                    <button (click)="global.goToApp('portfolio-creation')" class="blue outline mt15">
                        <img class="plus_icon" src="../../assets/images/plus.svg">
                        <span translate>Create a portfolio</span>
                    </button>
                </div>
            </div>
        </nav>

		<div class="projectListWrapper" [ngClass]="loading ? 'not-active' : ''">
            <app-loader *ngIf="loading"></app-loader>
            <div class="form-inline no-footer hidden-xs">
                <table class="table table-striped arc-table">
                    <thead>
                        <tr>
                            <!-- <th class="w20p">ID</th> -->
                            <th class="w45p" translate>Name</th>
                            <th class="hidden-xs w15p align-center" translate>Projects</th>
                            <th class="hidden-xs w20p align-center" translate>GRESB SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-tooltip-placement="top" title="{{feed_loader[portfolio.pf_id] ? 'Portfolio setup is in progress. Each asset takes approximately 1 minute to setup. The setup will continue even if you navigate away from this page and you will be notified by email upon completion.' : ''}}" *ngFor="let portfolio of myportfolios" class="project_row">
                            <td class="cursor-pointer p15 flex" (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio, undefined, feed_loader[portfolio.pf_id])">
                                <svg style="fill: #707479;" class="mr10" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><rect fill="none" height="24" width="24"/><path d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z"/></svg>
                                <span class="fw-500 mr10">{{portfolio.name}}</span>
                                <span class="fs13 gray_color_707479 pt2">{{portfolio.pf_id}}</span>
                            </td>
                            <td class="cursor-pointer align-center" (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio, undefined, feed_loader[portfolio.pf_id])">
                                <span *ngIf="!feed_loader[portfolio.pf_id]">{{portfolio.asset_count}}</span>
                                <div *ngIf="feed_loader[portfolio.pf_id]" class="ml50p dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                            </td>
                            <td class="cursor-pointer align-center" (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio, undefined, feed_loader[portfolio.pf_id])">
                                <div *ngIf="portfolio.source == 'gresb'">
                                    <span *ngIf="portfolio.source_score != 'None'" class="gresb_score">{{portfolio.source_score}}</span>
                                    <span *ngIf="portfolio.source_score == 'None'" class="gresb_score">&nbsp</span>
                                    <span>
                                        <img data-tooltip="GRESB portfolio" [src]="'../../assets/images/apps/gresb-only.png'" class="w20px">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        
            <div class="visible-xs certification-cards">            
                <div class="row" *ngFor="let portfolio of myportfolios; let idx = index;" class="project_row">
                    <div>
                        <div class="fw600 w60p pull-left" (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio)">{{portfolio.name}}
                        </div>               
                        <div [ngClass]="'project-row-'+ idx + ' ' + 'pull-right'" 
                        (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio)">No of Projects:{{portfolio.asset_count}}</div> 
                    </div>
        
                    <div class="clear-left" (click)="global.moveToPortfolio(portfolio.name, portfolio.pf_id, portfolio)">
                        {{portfolio.pf_id}}
                    </div>
                </div>        
            </div>
        
            <div *ngIf="loading_more_portfolios" class="ajaxLoader">
                <div class="loaderSpinner"></div>
                <div>Loading...</div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade gresb_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <!-- Modal content-->
        <div class="modal-content modalWindow-content">
            <div class="modal-header">
                <header class="logo">
                    <a href="appData.get('landing_page_url')" class="appLogo"></a>
                </header>
            </div>
            <div *ngIf="option_org" class="modal-body">
                <form name="organizationForm">           
                    <fieldset>
                        <section class="widget pt20" style="margin-bottom: 0px !important;padding: 15px 0px;"> 
                            <table style="border: none;" class="gresb_modal_table width-100-perc">
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="">
                                            <div [ngClass]="orgNameError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Organization Name:</div>
                                                <input (input)="validateOrgValues('orgName')" name="name" class="form-control" maxlength="100" type="text" [(ngModel)]="orgName">
        
                                                <span class="floatl width-100-perc error_message mb0 help-block required-field" *ngIf="orgNameError" translate>This field is required.</span>
                                                
                                                <span class="floatl error_message help-block required-field" *ngIf='isFormSubmittedOwner'>{{formValidation.nameMsg}}</span>
        
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            <div [ngClass]="orgContactNameError ? 'has-error': ' ' " class="mt10 mb10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Owner's Representative(Employee or Officer of Owner):</div>
                                                <input name="orgContactName" (input)="validateOrgValues('orgContactName')" class="form-control" maxlength="100" type="text" [(ngModel)]="orgContactName">
                                                
                                                <span class="floatl error_message help-block required-field" *ngIf="orgContactNameError" translate>This field is required.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            <div [ngClass]="orgContactEmailError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Owner Contact Email:</div>
                                                <input (input)="validateOrgValues('orgContactEmail')" name="owner_email"  class="form-control" maxlength="40" type="text" [(ngModel)]="orgContactEmail">
                                                <span class="mb0 floatl width-100-perc error_message help-block required-field" *ngIf="orgContactEmailError" translate>This field is required.</span>
                                                
                                                <span class="floatl error_message help-block required-field" *ngIf='isFormSubmittedOwner'>{{formValidation.emailMsg}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            <div [ngClass]="orgWebSiteError ? 'has-error': ' ' " class="mb10 mt10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Website:</div>
                                                <input name="orgWebSite" (input)="validateOrgValues('orgWebSite')" class="form-control" maxlength="256" type="text" [(ngModel)]="orgWebSite">
                                                
                                                <span class="floatl error_message help-block" *ngIf="orgWebSiteError" translate>Please enter valid web url.</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            <div class="mb10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Industry Category:</div>
                                                
                                                <select name="orgCategory" #elem (change)="resetSubCat(elem)" class="form-control" [(ngModel)]="orgCategory">
                                                    <option value="2" selected="selected">Product Manufacturers</option>
                                                    <option value="3">Utility / Energy Service Companies</option>
                                                    <option value="4">Real Estate Providers</option>
                                                    <option value="5">Professional Societies and Trade Associations</option>
                                                    <option value="6">Nonprofit and Environmental Organizations</option>
                                                    <option value="7">State and Local Governments</option>
                                                    <option value="8">Federal Government</option>
                                                    <option value="9">Professional Firms</option>
                                                    <option value="10">Contractors and Builders</option>
                                                    <option value="12">Educational Institutions</option>
                                                    <option value="13">Corporate and Retail</option>
                                                    <option value="501">Financial Institutions</option>
                                                </select>
                                                
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="">
                                            <div class="mb10" style="height: 66px;">
                                                <div class="mb10 font-weight-600" translate>Industry Sub-Category:</div>
                                                
                                                <select name="orgSubCategory" [ngClass]="orgSubCategoryError ? 'has-error': ' ' " class="form-control" [(ngModel)]="orgSubCategory">
                                                    <option *ngIf="orgCategory == '2'" value="27">PM:Product Manufacturer</option>
                                                    <option *ngIf="orgCategory == '2'" value="28">PM:Building Controls</option>
                                                    <option *ngIf="orgCategory == '2'" value="61">PM:Distributors</option>
                                                    
                                                    <option *ngIf="orgCategory == '3'" value="30">Energy Service Companies</option>
                                                    <option *ngIf="orgCategory == '3'" value="31">Utilities</option>
        
                                                    <option *ngIf="orgCategory == '4'" value="108">RE:Comm-Brokage and Mgm</option>
                                                    <option *ngIf="orgCategory == '4'" value="109">RE:Commerical REITs</option>
                                                    <option *ngIf="orgCategory == '4'" value="110">RE: Residential Brokerage  and  Management</option>
                                                    <option *ngIf="orgCategory == '4'" value="111">RE:Residential Property Investor</option>
                                                    <option *ngIf="orgCategory == '4'" value="112">RE: Institutional Investor</option>
                                                    <option *ngIf="orgCategory == '4'" value="113">RE:Private Held Owner</option>
                                                    <option *ngIf="orgCategory == '4'" value="114">RE:Service Contractor</option>
        
                                                    <option *ngIf="orgCategory == '5'" value="5">Prof Soc/Trade Assn</option>
                                                    <option *ngIf="orgCategory == '5'" value="104">TradeGrp and Labor-union</option>
                                                    <option *ngIf="orgCategory == '5'" value="105">Advocacy Organization</option>
        
                                                    <option *ngIf="orgCategory == '6'" value="100">Charitable.org and Foundation</option>
                                                    <option *ngIf="orgCategory == '6'" value="101">Socia and Membership org</option>
                                                    <option *ngIf="orgCategory == '6'" value="102">Religious</option>
                                                    <option *ngIf="orgCategory == '6'" value="103">Other Non-Profit</option>
        
                                                    <option *ngIf="orgCategory == '7'" value="62">SLG: State Government</option>
                                                    <option *ngIf="orgCategory == '7'" value="63">SLG: Local Government</option>
        
                                                    <option *ngIf="orgCategory == '8'" value="8">Federal Government</option>
        
                                                    <option *ngIf="orgCategory == '9'" value="32">PF:Accountants</option>
                                                    <option *ngIf="orgCategory == '9'" value="33">PF:Architects</option>
                                                    <option *ngIf="orgCategory == '9'" value="34">PF:Architects/Engineers</option>
                                                    <option *ngIf="orgCategory == '9'" value="35">PF:Attorneys</option>
                                                    <option *ngIf="orgCategory == '9'" value="36">PF:Building/LEED Consultant</option>
                                                    <option *ngIf="orgCategory == '9'" value="56">PF:Engineers</option>
                                                    <option *ngIf="orgCategory == '9'" value="57">PF:Interior Designer</option>
                                                    <option *ngIf="orgCategory == '9'" value="58">PF:Landscape Architect</option>
                                                    <option *ngIf="orgCategory == '9'" value="59">PF:Planners</option>
                                                    <option *ngIf="orgCategory == '9'" value="60">PF:Commissioning Pr</option>
                                                    <option *ngIf="orgCategory == '9'" value="74">PF:Residential Designer</option>
                                                    <option *ngIf="orgCategory == '9'" value="107">PF:Other</option>
        
                                                    <option *ngIf="orgCategory == '10'" value="75">CB:Commercial</option>
                                                    <option *ngIf="orgCategory == '10'" value="76">CB:Residential</option>
                                                    <option *ngIf="orgCategory == '10'" value="77">CB:Mixed</option>
                                                    <option *ngIf="orgCategory == '10'" value="78">CB:Others</option>
        
                                                    <option *ngIf="orgCategory == '12'" value="91">Indp/Nonprofit.Education</option>
                                                    <option *ngIf="orgCategory == '12'" value="92">E and S-sch:Public</option>
                                                    <option *ngIf="orgCategory == '12'" value="93">E and S-sch:Public Charter</option>
                                                    <option *ngIf="orgCategory == '12'" value="94">E and S-sch:Indpendent</option>
                                                    <option *ngIf="orgCategory == '12'" value="95">C and Uni:2yrs-Instiution</option>
                                                    <option *ngIf="orgCategory == '12'" value="96">C and Uni:4yrs-Inst(public)</option>
                                                    <option *ngIf="orgCategory == '12'" value="97">C and Uni:4yrs-Inst(private)</option>
                                                    <option *ngIf="orgCategory == '12'" value="98">C and Uni:4yrs-grad(public)</option>
                                                    <option *ngIf="orgCategory == '12'" value="99">C and Uni:4yrs-grad(private)</option>
        
                                                    <option *ngIf="orgCategory == '13'" value="11">Insurance Companies</option>
                                                    <option *ngIf="orgCategory == '13'" value="81">Health,Pharma and Biotec</option>
                                                    <option *ngIf="orgCategory == '13'" value="83">Retail: Restaurants, Bars and Food Services</option>
                                                    <option *ngIf="orgCategory == '13'" value="84">Retail: Grocery, Convenience Store</option>
                                                    <option *ngIf="orgCategory == '13'" value="85">Retail: Other</option>
                                                    <option *ngIf="orgCategory == '13'" value="86">Technology</option>
                                                    <option *ngIf="orgCategory == '13'" value="87">Transportation, Delivery and Warehousing Services</option>
                                                    <option *ngIf="orgCategory == '13'" value="89">Other Corporate</option>
                                                    <option *ngIf="orgCategory == '13'" value="501">Financial Institution</option>
        
                                                    <option *ngIf="orgCategory == '501'" value="501">Financial Institutions</option>
                                                </select>
                                                
                                                <span class="floatl error_message help-block required-field" *ngIf="orgSubCategoryError">This field is required.</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                        <div class="clearfix" [ngClass]="creating_org ? 'not-active' : ''">
                            <div class="btn-toolbar clear-left">
                                <button  class="btn btn-success btn-md" type="submit" translate (click)="createOrganization_gresb()">
                                    ADD
                                </button> 
                                <button (click)="switchView()" type="button" class="btn btn-default btn-md" translate>
                                    CANCEL
                                </button>
                                <div style="margin-top: 7px;" *ngIf="creating_org" class="ml10 mr10 dataInputLoaderSpinner floatl"></div>
                            </div>
                            <div class="error_message " *ngIf="error_org">{{error_msg_org}}</div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div *ngIf="!option_org" class="modal-body">
                <h5 class="fw-400 fontSize16" translate>
                    Import from GRESB
                </h5>
                <div class="flex mt20">
                    <div class="width80p">
                        <p class="fw-400 fontSize13" translate>Manage your GRESB project in Arc.</p>

                        <form class="pr30 mb20">           
                            <fieldset>
                                <section class="widget" style="margin-bottom: 0px !important;padding: 0px;"> 
                                    <table style="border: none;" class="gresb_modal_table width-100-perc">
                                        <thead>
                                            <tr>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="portfolio_name_error ? 'arc-input-error' : ''" class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Portfolio Name</div>
                                                        <input (input)="validateInputs('portfolio_name', portfolio_name)" class="form-control" maxlength="40" type="text" [(ngModel)]="portfolio_name" name="portfolio_name">
                                                        <p *ngIf="portfolio_name_error" class="reading_error_label" aria-hidden="false"  translate>{{portfolio_name_message}}</p>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="">
                                                    <div class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Gross Area Unit</div>
                                                        <select id="unitType" name="unitType" [(ngModel)]="unit_type" class="form-control">
                                                            <option value="IP">square feet</option>
                                                            <option value="SI">square meters</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="name_error ? 'arc-input-error' : ''" class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Owner Type</div>
                                                        <select id="ownerType" name="ownerType" [(ngModel)]="owner_type" class="form-control">
                                                            <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="organization_error ? 'arc-input-error' : ''" class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Owner Organization
                                                            <span translate class="fontSize12">(<span (click)="switchView()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>
                                                        </div>
                                                        <div class="search-dropdown">
                                                            <input name="organization" id="gresb-search" (blur)="hideOrgList()" (focus)="showOrgList()" (input)="queryOrg(organization)" [(ngModel)]="organization" class="h35p form-control input-sm" autocomplete="off" placeholder="Search"/>
                                                            <div class="search-list" style="display: none;">
                                                                <div (click)="selectOrg(org)" class="search-list-item" *ngFor="let org of org_list">
                                                                    {{org}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p *ngIf="organization_error" class="reading_error_label" aria-hidden="false" >{{organization_message}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="email_error ? 'arc-input-error' : ''" class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Owner Email</div>
                                                        <input (input)="validateInputs('email', owner_email)" class="form-control" maxlength="100" type="text" [(ngModel)]="owner_email" name="owner_email">
                                                        <p *ngIf="email_error" class="reading_error_label" aria-hidden="false" >{{email_message}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="owner_name_error ? 'arc-input-error' : ''" class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Owner Representative</div>
                                                        <input (input)="validateInputs('owner_name', owner_name)" class="form-control" maxlength="100" type="text" [(ngModel)]="owner_name" name="owner_name">
                                                        <p *ngIf="owner_name_error" class="reading_error_label" aria-hidden="false" >{{owner_name_message}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>Owner Country/Region</div>
                                                        <select name="owner_country" [(ngModel)]="owner_country" class="form-control" data-parsley-trigger="change">
                                                            <option *ngFor="let country of countries" [value]="country.code"  [selected]="country.code=='US'? true : false">{{country.name}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div class="mb15" >
                                                        <div class="mb10 font-weight-600" translate>GRESB Asset Type</div>
                                                        <div class="flex">
                                                            <div class="checkbox whole pl20 mt0 mr20">
                                                                <input name="whole" (change)="checkAssetInput()" [(ngModel)]="whole" type="checkbox" 
                                                                [ngModelOptions]="{standalone: true}">                
                                                                <label for="checkbox">
                                                                    Whole
                                                                </label>
                                                            </div>
                                                            <div class="checkbox base pl20 mt0 mr20">
                                                                <input name="base" (change)="checkAssetInput()" [(ngModel)]="base" type="checkbox" 
                                                                [ngModelOptions]="{standalone: true}">                
                                                                <label for="checkbox">
                                                                    Base
                                                                </label>
                                                            </div>
                                                            <div class="checkbox tenant pl20 mt0">
                                                                <input name="tenant" (change)="checkAssetInput()" [(ngModel)]="tenant" type="checkbox" 
                                                                [ngModelOptions]="{standalone: true}">                
                                                                <label for="checkbox">
                                                                    Tenant
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p *ngIf="asset_type_error" class="reading_error_label" aria-hidden="false"  translate>Please select an asset type</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </fieldset>
                        </form>  

                        <p class="fw-600">Please download and fill GRESB <a (click)="global.downloadXLS('2023-GRESB-Asset-Spreadsheet.xlsx')">template</a> and upload below <span class="fs12">(Each asset takes approximate 1 minute to setup)</span></p>
                        <!-- <p *ngIf="excelUploadData != undefined">
                            <i (click)="resetExcelSelection()" class="ml5 color_dark_gray cursor-pointer fa fa-trash"></i>
                        </p> -->
                        <div class="flex pt10">
                            <button [ngClass]="uploading_gresb ? 'not-active' : ''" (click)="global.openUploadDialog('laptop', '', 'S3')" class="btn btn-default btn-sm">
                                <i class="far fa-file-excel mr10"></i>
                                Select a file
                            </button>
                            <span *ngIf="excelUploadData != undefined" class="ellipsis fw-600 fontSize13 ml10 pt5">{{excelUploadName}}</span>
                        </div>
                    </div>
                    <div class="gresb_div">
                        <div>
                            <img [src]="'../../assets/images/apps/gresb_new.png'" class="mb15" style="width: 180px !important;">
                        </div>
                        <p class="fw-400 fontSize13" translate>Need a GRESB spreadsheet?</p>
                        <p class="fw-400 fontSize13 mt15"><a (click)="global.downloadXLS('2023-GRESB-Asset-Spreadsheet.xlsx')" translate>Download GRESB Template</a></p>
                    </div>
                </div>
            </div>
            <div *ngIf="!option_org" class="modal-footer align-left pl30 pr30 pb30 pt10" [ngClass]="uploading_gresb ? 'not-active' : ''">
                <button [ngClass]="email_error || owner_name_error || organization_error || name_error || portfolio_name_error || excelUploadData == undefined ? 'not-active' : ''" type="button" (click)="uploadGRESB()" class="gresb_upload btn btn-success btn-md">{{upload_gresb_text}}</button>
                <button data-dismiss="modal" type="button" class="btn btn-default btn-md" translate>CANCEL</button>
            </div>
        </div>
    </div>
</div>
