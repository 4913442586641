
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-12">
            <h1 class="page-title pl0 pb10">Recompute AnalysisInfo<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto pt30">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)=checkInputsCert() (focus)=checkInputsCert() [(ngModel)]="leed_id"
                                        type="text" id="normal-field" class="form-control" placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Recompute Date</label>
                                <div class="col-sm-7">
                                    <input (input)=checkInputsCert() id="end_date" [(ngModel)]="end_date"
                                        class="col-sm-7 form-control" [ngInit]="enableFdatePicker()" name="end_date"
                                        [ngClass]="loaderCert ? 'not-active' : ''">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <button (click)="recomputeScore()" [ngClass]="checkInputCert ? '' : 'not-active'"
                                        [disabled]="loaderCert" class="btn btn-default pull-left mr10"><i
                                            class="icon-save" aria-hidden="true"></i> Recompute</button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                            <div *ngIf="scores_received">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="w70p"></th>
                                            <th scope="col" class="w70p"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let key of Object.keys(kpis)">
                                            <td>{{kpis[key]['type']}}</td>
                                            <td>{{kpis[key]['value']}} <span *ngIf="kpis[key]['value']">({{kpis[key]['unit']}})</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>